import { DefaultAppAppends, DefaultAppWrappers } from '../../components/DefaultApp/DefaultApp'
import { MediaContextProvider, mediaStyles } from './components/Media'
import React from 'react'
import Head from 'next/head'

DefaultAppAppends.push(() => (
  <Head>
    <style type='text/css' dangerouslySetInnerHTML={{ __html: mediaStyles }} />
  </Head>
))

DefaultAppWrappers.unshift(function MediaContext({ children }) {
  return <MediaContextProvider>{children}</MediaContextProvider>
})
