import { createReducer } from '@reduxjs/toolkit';
import { none, Option, some } from 'fp-ts/lib/Option';
import { zoomCardActions } from 'src/state/zoom-card/zoomCardActions';

export interface ZoomCardState {
  closed: Option<boolean>;
}

const initialState: ZoomCardState = {
  closed: none,
};

export const zoomCardReducer = createReducer<ZoomCardState>(initialState, (builder) =>
  builder.addCase(zoomCardActions.close, (state) => {
    state.closed = some(true);
  }),
);
