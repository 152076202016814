import { rgba } from 'polished';

const blue = '#343f4e';
const grey = '#f5f6f7';
const darkGrey = '#ededee';
const red = '#b71833';

export const colors = {
  blue,
  grey,
  darkGrey,
  red,

  alto: '#E0E0E0',
  amazon: '#2d6a4f',
  bazaar: '#9B6D7F',
  beige: '#FAF8F3',
  beautyBush: '#edc5cc',
  blue025: rgba(blue, 0.25),
  blue05: '#6c727b',
  blue07: rgba(blue, 0.7),
  blue09: rgba(blue, 0.9),
  blue08: rgba(blue, 0.8),
  colonialWhite: '#ffecbb',
  darkGrey08: rgba(darkGrey, 0.8),
  feta: '#daf9d4',
  green1: '#219653',
  grey04: rgba(grey, 0.4),
  grey05: rgba(grey, 0.5),
  grey08: rgba(grey, 0.8),
  grey6: '#f2f2f2',
  pfp3: '#cdba8a',
  pfp3bis: '#936F15',
  porcelain: '#F7F8F9',
  red025: rgba(red, 0.25),
  redBright: '#e53935',
  white: '#ffffff',
  white085: rgba('#fff', 0.85),
  black: '#000000',
} as const;

export const shadows = {
  drop1: '0.625rem 0.625rem 1.875rem rgba(52, 63, 78, 0.2)',
  drop2: '0.125rem 0.125rem 0.3125rem rgba(52, 63, 78, 0.2)',
  drop3: '0.1875rem 0.1875rem 0.625rem rgba(52, 63, 78, 0.2)',
  modalFooter: '0.0625rem -0.125rem 0.25rem rgba(0, 0, 0, 0.15);',
  redHover: '0 0.125rem 0.125rem rgba(183, 24, 51, 0.25)',
} as const;
