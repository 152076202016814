import { ComponentType, FC, useEffect, useState } from 'react';

let firstRenderPerformed = false;

export const useClientReady = (): boolean => {
  const [shouldRender, setShouldRender] = useState(firstRenderPerformed);
  useEffect(() => {
    firstRenderPerformed = true;
    setShouldRender(true);
  }, [setShouldRender]);
  return shouldRender;
};

export const ClientOnly: FC = ({ children }) => {
  const shouldRender = useClientReady();
  return shouldRender ? <>{children}</> : <></>;
};

export function WithClientOnly<Props extends JSX.IntrinsicAttributes>(
  Component: ComponentType<Props>,
): FC<Props> {
  return WithClientOnly;

  function WithClientOnly(props: Props) {
    return (
      <ClientOnly>
        <Component {...props} />
      </ClientOnly>
    );
  }
}
