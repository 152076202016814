import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const InfoIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32 5.81818C17.5402 5.81818 5.81818 17.5402 5.81818 32 5.81818 46.4598 17.5402 58.1818 32 58.1818c14.4598 0 26.1818-11.722 26.1818-26.1818S46.4598 5.81818 32 5.81818zM0 32C0 14.3269 14.3269 0 32 0c17.6731 0 32 14.3269 32 32 0 17.6731-14.3269 32-32 32C14.3269 64 0 49.6731 0 32z"
    />
    <path d="M34.9082 21.8623c0 1.6067-1.3025 2.9092-2.9092 2.9092-1.6067 0-2.9092-1.3025-2.9092-2.9092 0-1.6067 1.3025-2.9092 2.9092-2.9092 1.6067 0 2.9092 1.3025 2.9092 2.9092z" />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M31.999 23.7715c1.0544 0 1.9092-.8548 1.9092-1.9092s-.8548-1.9092-1.9092-1.9092-1.9092.8548-1.9092 1.9092.8548 1.9092 1.9092 1.9092zm0 1c1.6067 0 2.9092-1.3025 2.9092-2.9092 0-1.6067-1.3025-2.9092-2.9092-2.9092-1.6067 0-2.9092 1.3025-2.9092 2.9092 0 1.6067 1.3025 2.9092 2.9092 2.9092z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32 27.4281c1.5878.0012 2.874 1.2894 2.8727 2.8772l-.0117 14.7435c-.0012 1.5878-1.2894 2.874-2.8773 2.8727-1.5878-.0012-2.8739-1.2894-2.8727-2.8772l.0117-14.7435c.0013-1.5878 1.2895-2.874 2.8773-2.8727z"
    />
  </IconBox>
);

export default InfoIcon;
