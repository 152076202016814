import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CategoryApplicationsIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g clipRule="evenodd" fillRule="evenodd">
      <path d="M31.99997 6.14954C17.72377 6.14954 6.15062 17.7232 6.15062 32c0 14.2768 11.57315 25.8504 25.84935 25.8504 14.2762 0 25.8494-11.5736 25.8494-25.8504S46.27617 6.14954 31.99997 6.14954zM.40632 32C.40632 14.5505 14.55127.40499 31.99997.40499c17.4487 0 31.5937 14.14552 31.5937 31.59502 0 17.4494-14.145 31.595-31.5937 31.595S.40632 49.4494.40632 32z" />
      <path d="M31.99997 17.6386c-7.9312 0-14.3607 6.4298-14.3607 14.3614 0 7.9315 6.4295 14.3613 14.3607 14.3613S46.36068 39.9315 46.36068 32c0-7.9316-6.4295-14.3614-14.3607-14.3614zM11.89497 32c0-11.1042 9.0013-20.1059 20.105-20.1059s20.105 9.0017 20.105 20.1059c0 11.1042-9.0013 20.1059-20.105 20.1059S11.89497 43.1042 11.89497 32z" />
      <path d="M31.99997 29.1277c-1.5862 0-2.8721 1.286-2.8721 2.8723s1.2859 2.8723 2.8721 2.8723 2.8722-1.286 2.8722-2.8723-1.286-2.8723-2.8722-2.8723zM23.38357 32c0-4.759 3.8577-8.6168 8.6164-8.6168 4.7587 0 8.6165 3.8578 8.6165 8.6168 0 4.7589-3.8578 8.6168-8.6165 8.6168s-8.6164-3.8579-8.6164-8.6168z" />
    </g>
  </IconBox>
);

export default CategoryApplicationsIcon;
