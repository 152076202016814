import { createMedia } from '@artsy/fresnel'
import getConfig from 'next/config'
import { CreateMediaResults } from '@artsy/fresnel/dist/Media'

const config = getConfig().publicRuntimeConfig.fresnel

const media = createMedia(config) as CreateMediaResults<
  Project.Style.Breakpoints,
  Project.Style.Interactions
>

export const mediaStyles = media.createMediaStyle()
export const Media = media.Media
export const MediaContextProvider = media.MediaContextProvider
