import React, { FC } from 'react';
import { StoreSnackbarController } from 'src/common/components/StoreSnackbarController';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { layers } from 'src/config/layers';

const useStyles = makeStyles({
  SnackbarProviderContainerRoot: {
    zIndex: layers.snackbar,
    '@media (max-width: 767px)': {
      bottom: 14,
      left: 20,
      right: 'auto',
      top: 'auto',
    },
  },
});

export const SnackbarProvider: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <NotistackSnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      classes={{
        containerRoot: classes.SnackbarProviderContainerRoot,
      }}
      maxSnack={5}
    >
      {children}
      <StoreSnackbarController />
    </NotistackSnackbarProvider>
  );
};
