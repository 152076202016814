import { createAction } from 'src/lib/createAction';
import { PostRedirectAction } from 'src/config/postRedirectAction';
import { ParsedUrlQuery } from 'querystring';

export interface RedirectUrl {
  action?: PostRedirectAction;
  pathname: string;
  publicRoute: boolean;
  query?: ParsedUrlQuery;
}

export const authActions = {
  setRedirectUrl: createAction('auth/setRedirectUrl')<RedirectUrl | null>(),
} as const;
