export interface ListWrapper<T> {
  items: T[];
}

export function wrap<T>(items: T[]): ListWrapper<T> {
  return { items };
}

export function unwrap<T>({ items }: ListWrapper<T>): T[] {
  return items;
}
