import { ref, string } from 'yup';
import { all, isNil, values } from 'ramda';
import { differenceInHours, parseISO } from 'date-fns';
import { coerceUTCDateString } from 'src/lib/coerceUTCDateString';
import { PartnerDTO } from 'src/common/models/partner';
import { StoredImageDTO } from 'src/common/models/image';

export const passwordRequirementsLabel =
  'min. 8 znaków • maks. 16 znaków • wielka litera • mała litera • cyfra • znak specjalny (@$!%*#?&)';

export const passwordValidationSchema = string()
  .required('Pole obowiązkowe')
  .min(8, 'Hasło musi mieć minimum ${min} znaków')
  .max(16, 'Hasło może mieć maksymalnie ${max} znaków')
  .matches(
    /^(?=.*[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż\d@$!%*#?&]{8,16}$/,
    `Hasło nie spełnia wymagań: ${passwordRequirementsLabel}`,
  );

export const repeatPasswordValidationSchema = (refKey: string) =>
  string()
    .required('Pole obowiązkowe')
    .oneOf([ref(refKey), ''], 'Hasła muszą być takie same');

export enum EmploymentStatus {
  Employee = 'EMPLOYEE',
  PlanningBusiness = 'SELF_EMPLOYMENT_PLANNED',
  SelfEmployed = 'SELF_EMPLOYED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
}

export enum Gender {
  M = 'MALE',
  F = 'FEMALE',
  O = 'OTHER',
}

export enum Voivodeship {
  DOLNOSLASKIE = 'DOLNOSLASKIE',
  KUJAWSKO_POMORSKIE = 'KUJAWSKO_POMORSKIE',
  LUBELSKIE = 'LUBELSKIE',
  LUBUSKIE = 'LUBUSKIE',
  LODZKIE = 'LODZKIE',
  MALOPOLSKIE = 'MALOPOLSKIE',
  MAZOWIECKIE = 'MAZOWIECKIE',
  OPOLSKIE = 'OPOLSKIE',
  PODKARPACKIE = 'PODKARPACKIE',
  PODLASKIE = 'PODLASKIE',
  POMORSKIE = 'POMORSKIE',
  SLASKIE = 'SLASKIE',
  SWIETOKRZYSKIE = 'SWIETOKRZYSKIE',
  WARMINSKO_MAZURSKIE = 'WARMINSKO_MAZURSKIE',
  WIELKOPOLSKIE = 'WIELKOPOLSKIE',
  ZACHODNIOPOMORSKIE = 'ZACHODNIOPOMORSKIE',
}

export const employmentStatusMenuItems = [
  { label: 'uczeń / student', value: EmploymentStatus.Student },
  { label: 'samozatrudniony', value: EmploymentStatus.SelfEmployed },
  { label: 'pracownik', value: EmploymentStatus.Employee },
  { label: 'niezatrudniony', value: EmploymentStatus.Unemployed },
  {
    label: 'planuję założyć swoją działalność',
    value: EmploymentStatus.PlanningBusiness,
  },
];

export const genderMenuItems = [
  { label: 'Kobieta', value: Gender.F },
  { label: 'Mężczyzna', value: Gender.M },
];

export const voivodeshipMenuItems = [
  { label: 'dolnośląskie', value: Voivodeship.DOLNOSLASKIE },
  { label: 'kujawsko-pomorskie', value: Voivodeship.KUJAWSKO_POMORSKIE },
  { label: 'lubelskie', value: Voivodeship.LUBELSKIE },
  { label: 'lubuskie', value: Voivodeship.LUBUSKIE },
  { label: 'łódzkie', value: Voivodeship.LODZKIE },
  { label: 'małopolskie', value: Voivodeship.MALOPOLSKIE },
  { label: 'mazowieckie', value: Voivodeship.MAZOWIECKIE },
  { label: 'opolskie', value: Voivodeship.OPOLSKIE },
  { label: 'podkarpackie', value: Voivodeship.PODKARPACKIE },
  { label: 'podlaskie', value: Voivodeship.PODLASKIE },
  { label: 'pomorskie', value: Voivodeship.POMORSKIE },
  { label: 'śląskie', value: Voivodeship.SLASKIE },
  { label: 'świętokrzyskie', value: Voivodeship.SWIETOKRZYSKIE },
  { label: 'warmińsko-mazurskie', value: Voivodeship.WARMINSKO_MAZURSKIE },
  { label: 'wielkopolskie', value: Voivodeship.WIELKOPOLSKIE },
  { label: 'zachodniopomorskie', value: Voivodeship.ZACHODNIOPOMORSKIE },
];

export interface UserDTO {
  avatar?: StoredImageDTO;
  companyName?: string;
  confirmed?: boolean;
  createDate: string;
  employmentStatus?: EmploymentStatus;
  firstName: string;
  gender: Gender;
  id: number;
  lastName: string;
  login: string;
  marketingPermission: boolean;
  partner: PartnerDTO | null;
  phoneNumber?: string;
  role: string;
  voivodeship?: string;
}

export interface UpdatePasswordDTO {
  newPassword: string;
  oldPassword: string;
}

export interface EmailUpdateDTO {
  newEmail: string;
  password: string;
}

export interface UserSummaryNextEvent {
  type: 'days' | 'hours';
  value: number;
}

export interface UserSummary {
  certificates?: number;
  completedTrainings?: number;
  nextEvent?: UserSummaryNextEvent;
  hours?: number;
}

export interface UserSummaryDTO {
  certificates: number;
  completedTrainings: number;
  hours: number;
  nextEventStartDateTime: string | null;
}

export const isSummaryEmpty = (x: UserSummary) => all(isNil, values(x));

export const coerceUserSummaryValue = (value: number | null) =>
  !isNil(value) && value >= 0 ? value : undefined;

export const parseUserSummaryNextEventStartDateTime = (
  dateTime: string | null,
): UserSummaryNextEvent | undefined => {
  if (!dateTime) {
    return;
  }

  const currentDateTime = new Date();
  const parsedDateTime = parseISO(coerceUTCDateString(dateTime));

  const diffInHours = differenceInHours(parsedDateTime, currentDateTime);

  if (diffInHours < 0) {
    return;
  }

  if (diffInHours < 24) {
    return {
      type: 'hours',
      value: diffInHours,
    };
  }

  return {
    type: 'days',
    value: Math.floor(diffInHours / 24),
  };
};

export const mapToUserSummary = (dto: UserSummaryDTO): UserSummary => ({
  certificates: coerceUserSummaryValue(dto.certificates),
  completedTrainings: coerceUserSummaryValue(dto.completedTrainings),
  hours: coerceUserSummaryValue(dto.hours),
  nextEvent: parseUserSummaryNextEventStartDateTime(dto.nextEventStartDateTime),
});
