import { AppProps } from 'next/app'
import React, { useMemo } from 'react'
import { DefaultErrorHandler } from '../../utils/DefaultErrorHandler'
import { DefaultAppWrappers } from '../../utils/DefaultAppWrappers'
import { DefaultContentsWrappers } from '../../utils/DefaultContentsWrappers'
import { DefaultAppAppends } from '../../utils/DefaultAppAppends'
import { useExtensionsFastRefresh } from '../../utils/fastRefreshTrigger'

export { DefaultAppWrappers } from '../../utils/DefaultAppWrappers'
export { DefaultContentsWrappers } from '../../utils/DefaultContentsWrappers'
export { DefaultAppAppends } from '../../utils/DefaultAppAppends'

const useCreatePageWrapper = (
  wrappers: Array<React.FunctionComponent<AppProps & { children: React.ReactNode }>>,
) =>
  useMemo(
    () =>
      wrappers.reduceRight(
        // eslint-disable-next-line react/display-name
        (Content, Wrapper) => (props: AppProps & { children: React.ReactNode }) =>
          <Wrapper {...props}>{Content(props)}</Wrapper>,
        (({ children }) => children) as React.FunctionComponent<
          AppProps & { children: React.ReactNode }
        >,
      ),

    [wrappers],
  )

/**
 * This is the default app setup that provides useful features.
 * You create and use your own implementation providing it to VendorApp instead of DefaultApp
 *
 * @param props
 */
export const DefaultApp: React.ComponentType<AppProps & { pageProps: VendorErrorProps }> = (
  props,
) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExtensionsFastRefresh()
  }
  const CombinedWrapper = useCreatePageWrapper(DefaultAppWrappers)
  const CombinedContentsWrapper = useCreatePageWrapper(DefaultContentsWrappers)

  return (
    <CombinedWrapper {...props}>
      {DefaultAppAppends.map((Append, i) => {
        return <Append key={Append.name || i} />
      })}
      <CombinedContentsWrapper {...props}>
        <DefaultErrorHandler key={props.router.route} {...props} />
      </CombinedContentsWrapper>
    </CombinedWrapper>
  )
}
