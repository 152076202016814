import { createReducer } from '@reduxjs/toolkit';
import { layoutActions } from 'src/state/layout/layoutActions';

export enum MobileMenuState {
  Categories = 'categories',
  Default = 'default',
  Hidden = 'hidden',
}

export interface LayoutState {
  activePanelCategory: string | null;
  mobileMenu: MobileMenuState;
}

const initialState: LayoutState = {
  activePanelCategory: null,
  mobileMenu: MobileMenuState.Hidden,
};

export const layoutReducer = createReducer<LayoutState>(initialState, (builder) =>
  builder
    .addCase(layoutActions.hideMobileMenu, (state) => {
      state.mobileMenu = MobileMenuState.Hidden;
    })
    .addCase(layoutActions.setActivePanelCategory, (state, action) => {
      state.activePanelCategory = action.payload;
    })
    .addCase(layoutActions.showMobileCategories, (state) => {
      state.mobileMenu = MobileMenuState.Categories;
    })
    .addCase(layoutActions.showMobileMenu, (state) => {
      state.mobileMenu = MobileMenuState.Default;
    }),
);
