import { createAction } from 'src/lib/createAction';
import { PageContextType } from 'src/state/pageContext/pageContextReducer';
import {
  AuthCourseExcerptPayload,
  PageContextPayload,
} from 'src/state/pageContext/pageContextPayloads';
import { RegisterFormModel, RequestResetPasswordFormModel } from 'src/common/models/auth';

export const pageContextActions = {
  setAuthCourseExcerpt: createAction('pageContext/setCourseExcerpt')<AuthCourseExcerptPayload>(),
  setRegisteredUserCache: createAction(
    'pageContext/setRegisteredUserCachePageContext',
  )<RegisterFormModel>(),
  setRequestResetPasswordFormCache: createAction(
    'pageContext/setRequestResetPasswordFormCachePageContext',
  )<RequestResetPasswordFormModel>(),
  setRedirectUrl: createAction('pageContext/setRedirectUrlPageContext')<PageContextPayload>(),
  cleanPageContext: createAction('pageContext/cleanPageContext')<PageContextType>(),
};
