import { fastRefreshTrigger } from 'vendor/utils/fastRefreshTrigger'

let _MutableDefaultAppExtensionsArray: new <T>(...items: T[]) => Array<T>

if (process.env.NODE_ENV !== 'production') {
  _MutableDefaultAppExtensionsArray = class MutableExtensionsArray<
    T extends (...args: any[]) => unknown,
  > extends Array<T> {
    indexMap = {} as { [name: string]: T }

    private updateAndFilter = (item: T): boolean => {
      const name = item.name
      if (name) {
        const alreadyAdded = this.indexMap[name]
        this.indexMap[name] = item
        if (alreadyAdded) {
          const alreadyAddedIndex = this.indexOf(alreadyAdded)
          console.log(`[MutableDefaultAppExtensionsArray] replace ${name}`)
          super.splice(alreadyAddedIndex, 1, item)
          return false
        } else {
          if (!fastRefreshTrigger.initialRender) {
            console.log(`[MutableDefaultAppExtensionsArray] adding ${name}`)
          }
        }
      } else if (!fastRefreshTrigger.initialRender) {
        console.warn(
          '[MutableDefaultAppExtensionsArray] Anonymous wrapper or append detected. In order for Fast Refresh to work correctly convert it to a named function.',
        )
        console.log(`[MutableDefaultAppExtensionsArray] adding [anonymous extension]`)
      }
      return true
    }

    private postUpdate = () => {
      fastRefreshTrigger.trigger()
    }

    push(...items: T[]): number {
      const number = super.push(...items.filter(this.updateAndFilter))
      this.postUpdate()
      return number
    }

    unshift(...items: T[]): number {
      const number = super.unshift(...items.filter(this.updateAndFilter))
      this.postUpdate()
      return number
    }
  }
} else {
  _MutableDefaultAppExtensionsArray = Array
}

export const MutableDefaultAppExtensionsArray = _MutableDefaultAppExtensionsArray
