import { QueryClient } from 'react-query';
import { AuthorizedAxios, MixedAxios } from 'src/common/services/axios';
import { Auth } from 'src/common/models/auth';
import { QueryKey } from 'src/config/queryKey';
import { writeToStorage } from 'src/common/services/queryStorage';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { createRefreshTokenInterceptor } from 'src/common/services/axios/refreshTokenInterceptor';
import { createAccessTokenInterceptor } from 'src/common/services/axios/accessTokenInterceptor';
import { logout } from 'src/common/services/auth';
import { createResponseErrorInterceptor } from 'src/common/services/axios/responseErrorInterceptor';
import { identity } from 'ramda';

export const axiosInterceptorsBind = (queryClient: QueryClient, deps: Record<any, any>) => {
  const { dispatch } = deps;

  const handleError = async () => {
    dispatch(
      snackbarActions.enqueueSnackbar({
        key: 'logout-warning',
        message: 'Twoja sesja wygasła. Zaloguj się ponownie.',
        options: {
          variant: 'warning',
        },
      }),
    );

    await logout(queryClient);
  };

  const refreshTokenInterceptor = createRefreshTokenInterceptor({
    getAuthData: () => queryClient.getQueryData<Auth>(QueryKey.Auth),
    onRefreshSuccess: async (auth) => {
      queryClient.setQueryData(QueryKey.Auth, auth);
      writeToStorage(QueryKey.Auth, auth);
    },
    onRefreshError: handleError,
  });

  const accessTokenInterceptor = createAccessTokenInterceptor({
    getAuthData: () => queryClient.getQueryData<Auth>(QueryKey.Auth),
  });

  const responseErrorInterceptor = createResponseErrorInterceptor({
    getAuthData: () => queryClient.getQueryData<Auth>(QueryKey.Auth),
    on401Response: handleError,
  });

  // Interceptors are defined in reverse order
  // RefreshToken should always trigger before AccessToken
  AuthorizedAxios.interceptors.request.use(accessTokenInterceptor);
  AuthorizedAxios.interceptors.request.use(refreshTokenInterceptor);
  AuthorizedAxios.interceptors.response.use(identity, responseErrorInterceptor);

  MixedAxios.interceptors.request.use(accessTokenInterceptor);
  MixedAxios.interceptors.request.use(refreshTokenInterceptor);
  MixedAxios.interceptors.response.use(identity, responseErrorInterceptor);
};
