import { persistReducer } from 'redux-persist';
import { PersistState } from 'redux-persist/es/types';
import { notificationReducer, NotificationState } from 'src/state/notification/notificationReducer';
import { cookiesReducer, CookiesState } from 'src/state/cookies/cookiesReducer';
import { zoomCardReducer, ZoomCardState } from 'src/state/zoom-card/zoomCardReducer';
import { authReducer, AuthState } from 'src/state/auth/authReducer';
import {
  courseListingReducer,
  CourseListingState,
} from 'src/state/courseListing/courseListingReducer';
import { layoutReducer, LayoutState } from 'src/state/layout/layoutReducer';
import { tutorialReducer, TutorialState } from 'src/state/tutorial/tutorialReducer';
import { homepageReducer, HomepageState } from 'src/state/homepage/homepageReducer';
import { storage } from 'src/state/storageProxy';
import { userMaterialsReducer } from 'src/user/materials/state/userMaterials/userMaterialsReducer';
import { pageContextReducer, PageContextState } from 'src/state/pageContext/pageContextReducer';
import { createOptionalTransform } from 'src/lib/optionalTransform';

interface PersistPartial {
  _persist: PersistState;
}

export interface RootState {
  auth: AuthState & PersistPartial;
  cookies: CookiesState & PersistPartial;
  courseListing: CourseListingState;
  homepage: HomepageState;
  layout: LayoutState;
  notification: NotificationState;
  pageContext: PageContextState;
  tutorial: TutorialState;
  zoomCard: ZoomCardState & PersistPartial;
}

export const createRootReducer = () => ({
  auth: persistReducer(
    {
      storage: storage,
      key: 'auth',
      whitelist: ['redirectUrl'],
      transforms: [createOptionalTransform(['redirectUrl'])],
    },
    authReducer,
  ),
  cookies: persistReducer(
    {
      storage: storage,
      key: 'cookies',
      whitelist: ['isAccepted'],
      transforms: [createOptionalTransform(['isAccepted'])],
    },
    cookiesReducer,
  ),
  pageContext: pageContextReducer,
  courseListing: courseListingReducer,
  homepage: homepageReducer,
  tutorial: tutorialReducer,
  layout: layoutReducer,
  notification: notificationReducer,
  userMaterials: userMaterialsReducer,
  zoomCard: persistReducer(
    {
      storage: storage,
      key: 'zoomCard',
      whitelist: ['closed'],
      transforms: [createOptionalTransform(['closed'])],
    },
    zoomCardReducer,
  ),
});
