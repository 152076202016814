import { StoredImageDTO } from './image';

export enum SpeakerUrlType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Web = 'WEB',
  Youtube = 'YOUTUBE',
}

export interface SpeakerUrlDTO {
  address: string;
  urlType: SpeakerUrlType;
}

export interface SpeakerDTO {
  averageRating?: number;
  id: number;
  bio: string;
  company: string;
  firstName: string;
  lastName: string;
  photo?: StoredImageDTO;
  recommendations?: number;
  signature?: string;
  urls: SpeakerUrlDTO[];
}

export interface Speaker {
  averageRating?: number;
  id: number;
  bio: string;
  company: string;
  firstName: string;
  lastName: string;
  photo?: StoredImageDTO;
  recommendations?: number;
  signature?: string;
  urls: SpeakerUrlDTO[];
}

export const mapToSpeaker: (data: SpeakerDTO) => Speaker = (dto) => dto;

export interface SpeakerExcerpt {
  id?: number;
  firstName: string;
  lastName: string;
  companyName: string;
}
