import { createReducer } from '@reduxjs/toolkit';
import { none, Option, some } from 'fp-ts/lib/Option';
import { authActions, RedirectUrl } from 'src/state/auth/authActions';

export interface AuthState {
  redirectUrl: Option<RedirectUrl>;
}

const initialState: AuthState = {
  redirectUrl: none,
};

export const authReducer = createReducer<AuthState>(initialState, (builder) =>
  builder.addCase(authActions.setRedirectUrl, (state, action) => {
    state.redirectUrl = action.payload ? some(action.payload) : none;
  }),
);
