import { parseISO } from 'date-fns';
import { coerceUTCDateString } from 'src/lib/coerceUTCDateString';
import { PdfFileIcon } from 'src/common/images/PdfFileIcon';
import { DocFileIcon } from 'src/common/images/DocFileIcon';
import { XlsFileIcon } from 'src/common/images/XlsFileIcon';
import OnlineIcon from 'src/assets/icons/OnlineIcon';
import React from 'react';
import { all, any, pickBy, values } from 'ramda';
import MaterialJpgIcon from 'src/assets/images/material-jpg.svg';
import MaterialPngIcon from 'src/assets/images/material-png.svg';
import MaterialPptIcon from 'src/assets/images/material-ppt.svg';
import MaterialTxtIcon from 'src/assets/images/material-txt.svg';
import { CourseType } from 'src/common/models/courseType';

export enum CourseMaterialExtension {
  PDF = 'pdf',
  PPT = 'ppt',
  PPTX = 'pptx',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  PNG = 'png',
  JPG = 'jpg',
  TXT = 'txt',
}

export enum CourseMaterialType {
  Blob = 'BLOB',
  Url = 'URL',
}

export interface CourseMaterial {
  createdAt: Date;
  extension?: CourseMaterialExtension;
  filename?: string;
  id: number;
  name: string;
  size?: number;
  type: CourseMaterialType;
  url?: string;
}

export interface CourseMaterials {
  available: boolean;
  eventId: number | null;
  id: number;
  materials: CourseMaterial[];
  title: string;
  type: CourseType;
}

export interface CourseMaterialDTO {
  createdAt: string;
  extension?: CourseMaterialExtension;
  filename?: string;
  id: number;
  name: string;
  size?: number;
  type: CourseMaterialType;
  url?: string;
}

export interface CourseMaterialsDTO {
  available: boolean;
  eventId: number | null;
  id: number;
  materials: CourseMaterialDTO[];
  title: string;
  type: CourseType;
}

export interface MaterialResourceObjectDTO {
  id: number;
  materialResource: CourseType;
  title: string;
}

export interface CourseMaterialsFilters {
  id: string;
  materialResource?: CourseType;
  title: string;
}

export const courseMaterialsFiltersInitialValues: CourseMaterialsFilters = {
  id: '',
  materialResource: undefined,
  title: '',
};

export interface CourseMaterialsSelectedItem {
  available: boolean;
  filename?: string;
  id: number;
  selected: boolean;
  type: CourseMaterialType;
}

export interface DownloadCourseMaterialDTO {
  url: string;
}

export type CourseMaterialsSelectedMap = {
  [id: number]: CourseMaterialsSelectedItem;
};

export interface CourseMaterialFileDTO {
  filename: string;
  id: number;
}

export interface UserCourseMaterialsCriteria {
  resourceId?: number;
  materialResource?: CourseType;
}

export const toAvailableOnlyArray = (x: CourseMaterialsSelectedMap): CourseMaterialsSelectedMap =>
  pickBy((item) => item.available && item.type === CourseMaterialType.Blob, x);

export const toSelectedOnlyArray = (x: CourseMaterialsSelectedMap): CourseMaterialsSelectedMap =>
  pickBy((item) => item.selected, x);

export const areAllSelected = (map: CourseMaterialsSelectedMap) =>
  all<CourseMaterialsSelectedItem>((item) => item.selected)(values(toAvailableOnlyArray(map)));

export const isAnySelected = (map: CourseMaterialsSelectedMap) =>
  any<CourseMaterialsSelectedItem>((item) => item.selected)(values(toAvailableOnlyArray(map)));

export const courseMaterialIconMapper = (courseMaterial: CourseMaterial) => {
  if (courseMaterial.type === CourseMaterialType.Url) {
    return <OnlineIcon />;
  }

  switch (courseMaterial.extension) {
    case CourseMaterialExtension.PDF:
      return <PdfFileIcon />;
    case CourseMaterialExtension.PPT:
    case CourseMaterialExtension.PPTX:
      return <MaterialPptIcon />;
    case CourseMaterialExtension.DOC:
    case CourseMaterialExtension.DOCX:
      return <DocFileIcon />;
    case CourseMaterialExtension.XLS:
    case CourseMaterialExtension.XLSX:
      return <XlsFileIcon />;
    case CourseMaterialExtension.PNG:
      return <MaterialPngIcon />;
    case CourseMaterialExtension.JPG:
      return <MaterialJpgIcon />;
    case CourseMaterialExtension.TXT:
      return <MaterialTxtIcon />;
    default:
      throw new Error('[courseMaterialIconMapper] Unknown course material icon type');
  }
};

export const mapDTOToCourseMaterial = (dto: CourseMaterialDTO): CourseMaterial => ({
  ...dto,
  createdAt: parseISO(coerceUTCDateString(dto.createdAt)),
  id: dto.id,
});

export const mapDTOToCourseMaterials = (dto: CourseMaterialsDTO): CourseMaterials => ({
  ...dto,
  eventId: dto.eventId ? dto.eventId : null,
  id: dto.id,
  materials: dto.materials.map(mapDTOToCourseMaterial),
});

export const mapToCourseMaterialFileDTO = (
  courseMaterial: CourseMaterial | CourseMaterialsSelectedItem,
): CourseMaterialFileDTO => ({
  filename: courseMaterial.filename || '',
  id: courseMaterial.id,
});
