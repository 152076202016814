import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const LightbulbIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path d="M32.0503 14.1636c-9.0312 0-16.4097 6.9072-16.4097 15.3616 0 4.4206 2.066 8.6754 5.5486 11.5488 2.5972 2.4313 2.3611 7.6255 2.3021 7.6808 0 .2763.059.4973.2951.7184.1771.1657.4722.2762.7084.2762h15.052c.2951 0 .5312-.1105.7083-.2762.1771-.1658.2952-.4421.2952-.7184 0-.0553-.2952-5.2495 2.302-7.6808.0591-.0553.1181-.1105.1771-.1658 3.4236-2.9286 5.4306-7.0729 5.4306-11.383 0-8.4544-7.3785-15.3616-16.4097-15.3616zm9.4444 25.4737c-.059.0553-.1771.1658-.1771.221-2.3021 2.3209-2.7153 6.1336-2.7743 7.9571H25.4982c-.059-1.8235-.4722-5.802-2.9513-8.1781-3.1285-2.5418-4.9584-6.2441-4.9584-10.1674 0-7.4598 6.434-13.4828 14.4028-13.4828 7.9687 0 14.4027 6.023 14.4027 13.4828 0 3.9233-1.7708 7.6256-4.8993 10.1674z" />
    <path
      fillRule="evenodd"
      d="M14.502 29.5249c0-9.0435 7.8885-16.4282 17.5491-16.4282 9.6605 0 17.5491 7.3847 17.5491 16.4282 0 4.6113-2.1418 9.0309-5.7821 12.154l-.1593.1491c-1.0282.9625-1.5608 2.5764-1.8032 4.1312-.2155 1.3825-.175 2.5139-.1665 2.7523.0008.0224.0014.0369.0014.0431 0 .5247-.2134 1.0836-.6289 1.4726-.3673.3438-.8851.5887-1.514.5887H24.4955c-.5348 0-1.1208-.2207-1.514-.5887-.5079-.4755-.6289-1.0009-.6289-1.4726v-.4419l.0312-.0291c.002-.1087.0023-.2403-.0006-.3915-.0094-.4953-.0525-1.1782-.1729-1.9285-.2471-1.54-.7864-3.1457-1.8028-4.1137-3.711-3.076-5.9055-7.6111-5.9055-12.325zm17.49-12.4714c-7.3394 0-13.2633 5.5455-13.2633 12.4162 0 3.6116 1.6846 7.0209 4.5658 9.3618l.0354.0288.0328.0315c1.4323 1.3726 2.2245 3.1602 2.6712 4.774.311 1.1239.466 2.2059.5422 3.0827h10.8911c.0751-.8587.225-1.9042.5184-2.9875.4098-1.5133 1.1207-3.1798 2.3759-4.503.0547-.0854.1121-.1529.1479-.1933.0711-.0798.1472-.1509.1759-.1777l.0329-.0308.0304-.0247c2.8729-2.3342 4.5067-5.7398 4.5067-9.3618 0-6.8707-5.9238-12.4162-13.2633-12.4162z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M36.1812 23.7094c-1.3689-.8984-2.8189-1.0466-3.343-.9536-.9274.1645-1.8217-.406-1.9975-1.2742-.1757-.8682.4337-1.7054 1.3611-1.8699 1.5681-.2781 3.9406.1642 5.9417 1.4775 2.1081 1.3835 3.8466 3.753 4.0112 7.3203.0408.8828-.6907 1.6294-1.6338 1.6675-.943.0382-1.7406-.6466-1.7813-1.5294-.1176-2.5466-1.2966-4.01-2.5584-4.8382z"
    />
    <path d="M38.5403 52.4883H25.4362c-1.3576 0-2.4792 1.0499-2.4792 2.3208 0 1.2709 1.1216 2.3208 2.4792 2.3208h13.0451c1.4166-.0552 2.5381-1.0499 2.5381-2.3208 0-1.2709-1.1215-2.3208-2.4791-2.3208zm0 2.7076H25.4362c-.2361 0-.4722-.1658-.4722-.4421 0-.2762.177-.442.4722-.442h13.0451c.2361 0 .4722.1658.4722.442 0 .2763-.1771.4421-.4132.4421z" />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M21.8184 54.8094c0-1.8601 1.6316-3.3875 3.6186-3.3875h13.1041c1.9869 0 3.6185 1.5274 3.6185 3.3875 0 1.9146-1.6829 3.3106-3.6301 3.3865l-.0237.0009H25.437c-1.987 0-3.6186-1.5274-3.6186-3.3874z"
    />
    <path d="M36.5957 58.0698h-9.2083c-1.3577 0-2.4792 1.0499-2.4792 2.3208 0 1.271 1.1215 2.3209 2.4792 2.3209h9.2083c1.3576 0 2.4791-1.0499 2.4791-2.3209 0-1.3261-1.1215-2.3208-2.4791-2.3208zm0 2.7076h-9.2083c-.2361 0-.4723-.1657-.4723-.442s.1771-.4421.4723-.4421h9.2083c.2361 0 .4722.1658.4722.4421s-.2361.442-.4722.442z" />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M23.7676 60.3904c0-1.86 1.6316-3.3875 3.6186-3.3875h9.2083c1.9691 0 3.6186 1.4557 3.6186 3.3875 0 1.86-1.6317 3.3875-3.6186 3.3875h-9.2083c-1.987 0-3.6186-1.5275-3.6186-3.3875z"
    />
    <path d="M31.9898 9.57721c.5903 0 1.0035-.44206 1.0035-.93938V2.22795c0-.49731-.4723-.93938-1.0035-.93938-.5313 0-1.0035.44207-1.0035.93938v6.40988c0 .49732.4722.93938 1.0035.93938z" />
    <path d="M48.6929 6.04007c-.4722-.33155-1.0625-.16577-1.3576.22103l-3.7778 5.3047c-.3542.4421-.2361 1.0499.2361 1.3262.1771.1105.3542.1658.5313.1658.3541 0 .6493-.1658.8263-.4421l3.7778-5.30471c.3542-.3868.2361-.99463-.2361-1.27092z" />
    <path d="M20.0088 12.8379c.177 0 .3541-.0552.5312-.1657.4722-.2763.5903-.8842.2952-1.3262l-3.6598-5.36c-.2951-.44206-.9444-.55258-1.4166-.27629-.4722.27629-.5903.88412-.2952 1.32618l3.6598 5.36001c.2361.3315.5312.442.8854.442z" />
    <path d="M12.8639 18.6948l-5.96175-3.0392c-.47222-.221-1.12152-.0553-1.35763.3868-.29514.442-.11805 1.0499.41319 1.2709l5.96179 3.0392c.1771.0552.2951.1105.4722.1105.3542 0 .7083-.1658.8854-.4973.2361-.4421.0591-1.0499-.4132-1.2709z" />
    <path d="M58.4953 16.0424c-.2361-.4421-.8854-.6078-1.3576-.3868l-6.0208 3.0392c-.4722.221-.6493.8288-.4132 1.2709.1771.3315.5313.4973.8854.4973.1771 0 .3542-.0553.4723-.1105l6.0207-3.0392c.4723-.221.6493-.8289.4132-1.2709z" />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M29.846 2.22773c0-1.08643.9824-2.00605 2.1429-2.00605 1.1606 0 2.1429.91962 2.1429 2.00605v6.40988c0 1.04496-.8799 2.00609-2.1429 2.00609-1.1605 0-2.1429-.91967-2.1429-2.00609V2.22773z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M49.3387 5.16131c.963.59473 1.2722 1.88027.5072 2.78498l-3.7346 5.24411c-.377.5694-1.0271.9344-1.7868.9344-.438 0-.8266-.1376-1.1498-.3366-1.0423-.6207-1.2563-1.9221-.5513-2.8339l3.7736-5.29884.0107-.014c.5881-.7707 1.8608-1.20107 2.931-.48015z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M18.1351 5.41502c-.663-.98628-2.0334-1.16578-2.9831-.61008-1.0587.61939-1.2522 1.9053-.6575 2.79604l3.6658 5.36912.0121.0172c.2111.2965.4866.5438.8369.7077.3451.1616.6925.2094.9965.2094.438 0 .8265-.1377 1.1498-.3366 1.0458-.6226 1.2348-1.9008.6429-2.7874l-3.6634-5.36538z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M4.5502 15.5205c.56654-.9953 1.91046-1.2641 2.86157-.8189l.01784.0083 5.96519 3.0409c1.0851.5225 1.381 1.7942.9015 2.692-.3968.7429-1.182 1.0869-1.9045 1.0869-.3821 0-.7371-.1303-.8172-.1597-.0078-.0029-.0154-.0055-.0154-.0055l-.0959-.0299-6.01761-3.0677c-1.13114-.5026-1.49615-1.7922-.89549-2.7464z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M56.6253 14.7016c.9653-.4518 2.3352-.1682 2.8864.8638.4799.8985.1831 2.1714-.904 2.6931l-6.0238 3.0407-.0157.0074c-.2248.1052-.5781.2231-.9818.2231-.7226 0-1.5078-.344-1.9046-1.0869-.4799-.8985-.1831-2.1714.904-2.6932l6.0238-3.0407.0157-.0073z"
    />
  </IconBox>
);

export default LightbulbIcon;
