import { createReducer } from '@reduxjs/toolkit';
import { SelectedMap } from 'src/lib/SelectedMap';
import { courseListingActions } from 'src/state/courseListing/courseListingActions';
import { values } from 'ramda';
import { HYDRATE_ACTION } from 'src/state/actions';

export enum CourseViewType {
  All = 'All',
  Online = 'Online',
  Elearning = 'Elearning',
  CourseBundle = 'CourseBundle',
}

export interface CourseListingState {
  selectedCategories: SelectedMap;
  selectedLevels: SelectedMap;
  selectedSpeakers: SelectedMap;
  showAllCategories: boolean;
  total: number;
  totalOnlineCourses: number;
  totalElearningCourses: number;
  totalCourseBundles: number;
  courseTypeView: CourseViewType;
  pageIndex: number;
}

const initialState: CourseListingState = {
  selectedCategories: {},
  selectedLevels: {},
  selectedSpeakers: {},
  showAllCategories: true,
  total: 0,
  totalOnlineCourses: 0,
  totalElearningCourses: 0,
  totalCourseBundles: 0,
  courseTypeView: CourseViewType.All,
  pageIndex: 1,
};

export const courseListingReducer = createReducer<CourseListingState>(initialState, (builder) =>
  builder
    .addCase(HYDRATE_ACTION, (state, action) => {
      state.selectedCategories = action.payload.courseListing.selectedCategories;
      state.showAllCategories = action.payload.courseListing.showAllCategories;
    })
    .addCase(courseListingActions.clearFilters, (state) => {
      return {
        selectedCategories: {},
        selectedLevels: {},
        selectedSpeakers: {},
        showAllCategories: true,
        total: state.total,
        totalOnlineCourses: state.totalOnlineCourses,
        totalElearningCourses: state.totalElearningCourses,
        totalCourseBundles: state.totalCourseBundles,
        courseTypeView: CourseViewType.All,
        pageIndex: 1,
      };
    })
    .addCase(courseListingActions.selectCategory, (state, action) => {
      state.selectedCategories[action.payload] = true;
    })
    .addCase(courseListingActions.setShowAllCategories, (state, action) => {
      state.showAllCategories = action.payload;
    })
    .addCase(courseListingActions.setTotalOnlineCourses, (state, action) => {
      state.total = action.payload;
    })
    .addCase(courseListingActions.setTotalElearningCourses, (state, action) => {
      state.totalElearningCourses = action.payload;
    })
    .addCase(courseListingActions.setTotalCourseBundles, (state, action) => {
      state.totalCourseBundles = action.payload;
    })
    .addCase(courseListingActions.toggleCategory, (state, action) => {
      state.selectedCategories[action.payload] = !state.selectedCategories[action.payload];
      state.pageIndex = 1;

      if (!values(state.selectedCategories).some(Boolean)) {
        state.showAllCategories = true;
      }
    })
    .addCase(courseListingActions.toggleLevel, (state, action) => {
      state.selectedLevels[action.payload] = !state.selectedLevels[action.payload];
    })
    .addCase(courseListingActions.toggleSpeaker, (state, action) => {
      state.selectedSpeakers[action.payload] = !state.selectedSpeakers[action.payload];
    })
    .addCase(courseListingActions.toggleCourseTypeView, (state, action) => {
      state.courseTypeView = action.payload;
      state.pageIndex = 1;
    })
    .addCase(courseListingActions.setPageIndex, (state, action) => {
      state.pageIndex = action.payload;
    }),
);
