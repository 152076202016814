import React, { forwardRef, ReactNode, useCallback } from 'react';
import { OptionsObject, SnackbarContent, useSnackbar, VariantType } from 'notistack';
import styled, { ThemeProvider } from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import CloseIcon from 'src/assets/icons/CloseIcon';
import {
  CustomVariantType,
  lightTypeVariantMapper,
  messageVariants,
  SnackbarMessageStyledProps,
  SnackbarMessageThemeParams,
  variantIcons,
} from 'src/common/components/StoreSnackbarController/config';
import { StoreSnackbarIcon } from 'src/common/components/StoreSnackbarController/StoreSnackbarIcon';
import { NotificationType } from 'src/state/notification/notificationActions';

const getSnackbarVariant = (type: NotificationType, variant?: VariantType) => {
  if (variant) {
    return type === 'light' ? (lightTypeVariantMapper[variant] as CustomVariantType) : variant;
  }

  return 'default';
};

const CloseButton = styled(CleanButton)`
  color: ${({ theme }) =>
    ['default', 'info'].includes(theme.variant) ? colors.white : colors.blue};
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const Message = styled.div`
  align-items: center;
  border-radius: 0.375rem;
  box-shadow: ${shadows.drop1};
  color: ${colors.blue};
  display: flex;
  max-width: 100%;
  min-height: 3.5rem;
  padding: 0.75rem 3.75rem 0.75rem 4.5rem;
  position: relative;
  width: 31.5625rem;

  &::before {
    background: ${colors.white};
    border-radius: 0.25rem;
    bottom: 0.3125rem;
    content: '';
    left: 0.3125rem;
    position: absolute;
    top: 0.3125rem;
    width: 0.25rem;
  }

  ${({ theme }: SnackbarMessageStyledProps) => messageVariants[theme.variant]}
`;

export interface SnackbarMessageProps {
  id: string | number;
  options: OptionsObject;
  message: string | ReactNode;
  type: NotificationType;
}

export const StoreSnackbarMessage = forwardRef<HTMLDivElement, SnackbarMessageProps>(
  ({ id, options, message, type }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleClose = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        closeSnackbar(id);
        event.stopPropagation();
      },
      [id, closeSnackbar],
    );

    const themeParams: SnackbarMessageThemeParams = {
      variant: getSnackbarVariant(type, options.variant),
    };

    return (
      <SnackbarContent ref={ref}>
        <ThemeProvider theme={themeParams}>
          <Message>
            {message}

            <StoreSnackbarIcon as={variantIcons[themeParams.variant]} size={24} />

            <CloseButton onClick={handleClose}>
              <CloseIcon size={20} />
            </CloseButton>
          </Message>
        </ThemeProvider>
      </SnackbarContent>
    );
  },
);
StoreSnackbarMessage.displayName = 'StoreSnackbarMessage';
