import { curry, evolve, map } from 'ramda';
import { parse, parseISO } from 'date-fns';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { coerceUTCDateString } from 'src/lib/coerceUTCDateString';
import { mapToSpeaker, Speaker, SpeakerDTO } from 'src/common/models/speaker';
import { SurveyStatus } from 'src/common/models/survey';
import { fromNullable, map as mapOption, toUndefined } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { CourseType } from 'src/common/models/courseType';
import { getCourseEventSlugRoute } from 'src/course/routes';
import { StoredImageDTO } from './image';

export enum RecordingStatus {
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
}

export const homepageTabName = {
  [CourseType.StandardCourse]: 'Szkolenia Online',
  [CourseType.ElearningCourse]: 'E-Learning',
  [CourseType.CourseBundle]: 'Kursy',
};

export interface EventListItemDTO {
  readonly id: number;
  courseId: number;
  courseImage: StoredImageDTO;
  courseTitle: string;
  courseSlug?: string;
  speaker: SpeakerDTO;
  startDateTime: string;
}

export interface EventListItem {
  readonly id: number;
  courseId: number;
  courseImage: StoredImageDTO;
  courseTitle: string;
  courseSlug?: string;
  speaker: Speaker;
  startDateTime?: Date;
}

export const mapToEventListItem: (dto: EventListItemDTO) => EventListItem = evolve({
  startDateTime: (v: string) => parseISO(coerceUTCDateString(v)),
  speaker: mapToSpeaker,
});

export interface AgendaDTO {
  startTime: string;
  item: string;
}

export interface Agenda {
  item: string;
  dateTime: Date;
}

export enum CourseEventStatus {
  Open = 'OPEN',
  Ongoing = 'ONGOING',
  Realized = 'REALIZED',
  Removed = 'REMOVED',
}

export interface EventDTO {
  readonly id: number;
  agendaList?: AgendaDTO[];
  courseId: number;
  duration: number; // in minutes
  location: string;
  remarks?: string;
  startDateTime: string;
  status: CourseEventStatus;
  trainerId: number;
  webinarJoinUrl?: string;
}

export enum AvailabilityStatus {
  Available = 'AVAILABLE',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Full = 'FULL',
  NotAvailable = 'NOT_AVAILABLE',
  OtherEvent = 'OTHER_EVENT',
  Trainer = 'TRAINER',
}

export interface EventWithAvailabilityStatusDTO extends EventDTO {
  availabilityStatus: AvailabilityStatus;
}

export interface CourseEvent {
  readonly id: number;
  courseId: number;
  duration: number;
  location: string;
  status: CourseEventStatus;
  trainerId: number;
  agendaList?: Agenda[];
  startDateTime: Date;
  availabilityStatus?: AvailabilityStatus;
  remarks?: string;
  webinarJoinUrl?: string;
}

export const mapToAgendaFactory = curry(
  (eventStartDateTime: Date, { item, startTime }: AgendaDTO): Agenda => {
    // parse doesn't consider source date timezone
    const agendaDateTimeUTC = parse(startTime, 'HH:mm:ss', eventStartDateTime);
    // const utcToZonedOffset = eventStartDateTime.getTimezoneOffset() * -1;

    // const dateTime = addMinutes(agendaDateTimeUTC, utcToZonedOffset);

    return {
      item,
      dateTime: agendaDateTimeUTC,
    };
  },
);

export const mapToCourseEvent = ({
  agendaList,
  startDateTime,
  ...rest
}: EventDTO | EventWithAvailabilityStatusDTO): CourseEvent => {
  const newStartDateTime = parseISO(coerceUTCDateString(startDateTime));
  const mapToEventLocalTimeAgenda = mapToAgendaFactory(newStartDateTime);

  return {
    ...rest,
    startDateTime: newStartDateTime,
    agendaList: agendaList?.map(mapToEventLocalTimeAgenda),
  };
};

export const mapToCourseExcerpt = (event: EventListItem): CourseExcerpt => ({
  id: event.id,
  title: event.courseTitle,
  slug: event.courseSlug,
  courseId: event.courseId,
  image: event.courseImage,
  date: event.startDateTime,
  courseType: CourseType.StandardCourse,

  elearningUserEnrollment: null,
  link: getCourseEventSlugRoute(event, {
    slug: event.courseSlug,
    id: event.courseId,
  }),

  speaker: pipe(
    fromNullable(event.speaker),
    mapOption((a) => ({
      id: a.id,
      firstName: a.firstName,
      lastName: a.lastName,
      companyName: a.company,
    })),
    toUndefined,
  ),
});

export enum UserParticipantStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Disenrolled = 'DISENROLLED',
  Present = 'PRESENT',
  Absent = 'ABSENT',
  Trainer = 'TRAINER',
}

export interface EventUserListItemDTO {
  readonly id: number;
  agendaList: AgendaDTO[];
  courseId: number;
  description: string;
  duration: number;
  joinUrl: string;
  location: string;
  recordingAvailableUntil: string;
  recordingStatus: RecordingStatus;
  startDateTime: string;
  status: CourseEventStatus;
  surveyAvailableUntil: string;
  surveyStatus: SurveyStatus;
  trainerId: number;
  courseTitle: string;
  courseImage: StoredImageDTO;
  speaker: SpeakerDTO;
}

export interface EventUserListItem {
  readonly id: number;
  agendaList: Agenda[];
  courseId: number;
  description: string;
  duration: number;
  joinUrl: string;
  location: string;
  recordingAvailableUntil: Date;
  recordingStatus: RecordingStatus;
  startDateTime: Date;
  status: CourseEventStatus;
  surveyAvailableUntil: Date;
  surveyStatus: SurveyStatus;
  trainerId: number;
  courseTitle: string;
  courseImage: StoredImageDTO;
  speaker: Speaker;
}

export const mapToEventUserListItem = (a: EventUserListItemDTO): EventUserListItem => {
  const newStartDateTime = parseISO(coerceUTCDateString(a.startDateTime));
  const mapToEventLocalTimeAgenda = mapToAgendaFactory(newStartDateTime);

  return evolve({
    recordingAvailableUntil: (v: string) => parseISO(coerceUTCDateString(v)),
    startDateTime: (v: string) => parseISO(coerceUTCDateString(v)),
    surveyAvailableUntil: (v: string) => parseISO(coerceUTCDateString(v)),
    speaker: mapToSpeaker,
    agendaList: map(mapToEventLocalTimeAgenda),
  })(a);
};
