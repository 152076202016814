import { addSeconds } from 'date-fns';

export interface AuthReqDTO {
  username: string;
  password: string;
}

export interface AuthDTO {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export interface Auth extends AuthDTO {
  expires_at?: string;
}

export const mapToAuth = (a: AuthDTO): Auth => ({
  ...a,
  expires_at: addSeconds(new Date(), a.expires_in).toISOString(),
});

export interface TokenDTO {
  token: string;
}

export interface LoginDTO {
  login: string;
}

export interface LoginFormModel {
  login: string;
  password: string;
}

export interface RegisterDTO extends LoginFormModel {
  firstName: string;
  lastName: string;
  dataProcessingPermission: boolean;
  marketingPermission: boolean;
  termsAndConditionsPermission: boolean;
  partnerCode?: string;
}

export interface RegisterFormModel extends LoginFormModel {
  firstName: string;
  lastName: string;
  dataProcessingPermission: boolean;
  marketingPermission: boolean;
  termsAndConditionsPermission: boolean;
  partnerCode?: string;
}

export interface ForgotPasswordPageState {
  email: string;
}

export interface RequestResetPasswordFormModel {
  email: string;
}

export interface ResetPasswordDTO {
  newPassword: string;
  token: string;
}

export type ResetPasswordModel = ResetPasswordDTO;

export const mapToAuthReqDTO = ({ login, password }: LoginFormModel): AuthReqDTO => ({
  username: login,
  password,
});

export const mapToRegisterDTO = ({
  firstName,
  lastName,
  login,
  password,
  dataProcessingPermission,
  marketingPermission,
  termsAndConditionsPermission,
  partnerCode,
}: RegisterFormModel): RegisterDTO => ({
  firstName,
  lastName,
  login,
  password,
  dataProcessingPermission,
  marketingPermission,
  termsAndConditionsPermission,
  partnerCode,
});

export const mapToForgotPasswordPageState = ({
  login,
}: {
  login: string;
}): ForgotPasswordPageState => ({
  email: login,
});

export const mapToLoginDTO = ({ email: login }: RequestResetPasswordFormModel): LoginDTO => ({
  login,
});

export const mapToResetPasswordDTO = (data: ResetPasswordModel): ResetPasswordDTO => data;
