import { QueryFunctionContext } from 'react-query';

export interface RowBoundsDTO {
  limit: number;
  offset: number;
}

export interface PageDTO {
  page: number;
  pageOrDefault?: number;
  pageSize: number;
  pageSizeOrDefault?: number;
  rowBounds?: RowBoundsDTO;
}

export interface PageResultDTO<T> {
  result: T[];
  totalResult: number;
}

export interface GetInfiniteQueryFunctionContext extends QueryFunctionContext {
  pageSize?: number;
}

export function unwrap<T>({ result }: PageResultDTO<T>): T[] {
  return result;
}
