import { concat } from 'ramda';
import { getSlug, Sluggable } from 'src/common/services/slug';
import { buildPathname, buildRoute, translateSegments } from 'src/lib/router.utils';
import { setParams } from 'src/common/utils/routing';

export const courseCourseIdParam = ':courseId';
export const courseEventIdParam = ':eventId';
export const courseSlugParam = ':slug';

const sourceCongratulationsSegment = 'congratulations';
const sourceItemSegment = 'course';
const sourceSuccessSegment = 'success';
const sourceSurveySegment = 'survey';
const sourceTutorialSegment = 'tutorial';

export const courseSegmentsTranslations: Record<string, string> = {
  [sourceCongratulationsSegment]: 'gratulacje',
  [sourceItemSegment]: 'szkolenie-online',
  [sourceSuccessSegment]: 'szkolenie-zakonczone',
  [sourceSurveySegment]: 'ankieta',
  [sourceTutorialSegment]: 'samouczek',
};

const segmentTranslator = (segments: string[]) =>
  translateSegments(segments, courseSegmentsTranslations);

const sourceItemRouteSegments = [sourceItemSegment, courseSlugParam, courseCourseIdParam];
const sourceEventRouteSegments = concat(sourceItemRouteSegments, [courseEventIdParam]);
const sourceCongratulationsRouteSegments = concat(sourceEventRouteSegments, [
  sourceCongratulationsSegment,
]);
const sourceSuccessRouteSegments = concat(sourceEventRouteSegments, [sourceSuccessSegment]);
const sourceSurveyRouteSegments = concat(sourceEventRouteSegments, [sourceSurveySegment]);
const sourceTutorialRouteSegments = concat(sourceEventRouteSegments, [sourceTutorialSegment]);

const translatedItemRouteSegments = segmentTranslator(sourceItemRouteSegments);
const translatedEventRouteSegments = segmentTranslator(sourceEventRouteSegments);
const translatedCongratulationsRouteSegments = segmentTranslator(
  sourceCongratulationsRouteSegments,
);
const translatedSuccessRouteSegments = segmentTranslator(sourceSuccessRouteSegments);
const translatedSurveyRouteSegments = segmentTranslator(sourceSurveyRouteSegments);
const translatedTutorialRouteSegments = segmentTranslator(sourceTutorialRouteSegments);

const SourceCourseItemRoute = buildRoute(sourceItemRouteSegments);
const SourceCourseEventRoute = buildRoute(sourceEventRouteSegments);
const SourceCourseCongratulationsRoute = buildRoute(sourceCongratulationsRouteSegments);
const SourceCourseSuccessRoute = buildRoute(sourceSuccessRouteSegments);
const SourceCourseSurveyRoute = buildRoute(sourceSurveyRouteSegments);
const SourceCourseTutorialRoute = buildRoute(sourceTutorialRouteSegments);

const TranslatedCourseItemRoute = buildRoute(translatedItemRouteSegments);
const TranslatedCourseEventRoute = buildRoute(translatedEventRouteSegments);
const TranslatedCourseCongratulationsRoute = buildRoute(translatedCongratulationsRouteSegments);
const TranslatedCourseSuccessRoute = buildRoute(translatedSuccessRouteSegments);
const TranslatedCourseSurveyRoute = buildRoute(translatedSurveyRouteSegments);
const TranslatedCourseTutorialRoute = buildRoute(translatedTutorialRouteSegments);

export const CourseEventRoute = TranslatedCourseEventRoute;
export const CourseEventCongratulationsRoute = TranslatedCourseCongratulationsRoute;
export const CourseEventSurveyRoute = TranslatedCourseSurveyRoute;
export const CourseEventTutorialRoute = TranslatedCourseTutorialRoute;
export const CourseItemRoute = TranslatedCourseItemRoute;

export const coursePathnameTranslations = {
  [buildPathname(sourceItemRouteSegments)]: buildPathname(translatedItemRouteSegments),
  [buildPathname(sourceEventRouteSegments)]: buildPathname(translatedEventRouteSegments),
  [buildPathname(sourceCongratulationsRouteSegments)]: buildPathname(
    translatedCongratulationsRouteSegments,
  ),
  [buildPathname(sourceSuccessRouteSegments)]: buildPathname(translatedSuccessRouteSegments),
  [buildPathname(sourceSurveyRouteSegments)]: buildPathname(translatedSurveyRouteSegments),
  [buildPathname(sourceTutorialRouteSegments)]: buildPathname(translatedTutorialRouteSegments),
};

export const courseTranslatedRoutes = [
  { source: SourceCourseItemRoute, translated: TranslatedCourseItemRoute },
  { source: SourceCourseEventRoute, translated: TranslatedCourseEventRoute },
  { source: SourceCourseCongratulationsRoute, translated: TranslatedCourseCongratulationsRoute },
  { source: SourceCourseSuccessRoute, translated: TranslatedCourseSuccessRoute },
  { source: SourceCourseSurveyRoute, translated: TranslatedCourseSurveyRoute },
  { source: SourceCourseTutorialRoute, translated: TranslatedCourseTutorialRoute },
];

export const getCourseSlugRoute = (
  courseLike: {
    id: number;
  } & Sluggable,
  route = CourseItemRoute,
) => {
  return setParams(courseCourseIdParam, courseSlugParam)(route)(
    String(courseLike.id),
    getSlug(courseLike),
  );
};

export const getCourseEventSlugRoute = (
  eventLike: {
    id: number;
  },
  courseLike: {
    id: number;
  } & Sluggable,
  route = CourseEventRoute,
) => {
  return setParams(courseCourseIdParam, courseEventIdParam, courseSlugParam)(route)(
    String(courseLike.id),
    String(eventLike.id),
    getSlug(courseLike),
  );
};

export const getCourseCongratulationsPageRoute = (
  eventLike: {
    id: number;
  },
  courseLike: {
    id: number;
  } & Sluggable,
) => getCourseEventSlugRoute(eventLike, courseLike, CourseEventCongratulationsRoute);

export const getCourseSurveyPageRoute = (
  eventLike: {
    id: number;
  },
  courseLike: {
    id: number;
  } & Sluggable,
) => getCourseEventSlugRoute(eventLike, courseLike, CourseEventSurveyRoute);

export const getCourseTutorialPageRoute = (
  eventLike: {
    id: number;
  },
  courseLike: {
    id: number;
  } & Sluggable,
) => getCourseEventSlugRoute(eventLike, courseLike, CourseEventTutorialRoute);
