import { createReducer } from '@reduxjs/toolkit';
import { homepageActions } from 'src/state/homepage/homepageActions';
import { CourseType } from 'src/common/models/courseType';

export interface HomepageState {
  homepageViewType: CourseType;
}

const initialState: HomepageState = {
  homepageViewType: CourseType.StandardCourse,
};

export const homepageReducer = createReducer<HomepageState>(initialState, (builder) =>
  builder.addCase(homepageActions.setViewType, (state, action) => {
    state.homepageViewType = action.payload;
  }),
);
