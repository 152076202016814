import { css } from 'styled-components';
import { typography } from 'src/config/typography';

export type TextDecoration = 'default' | 'underline';
export type TextVariant = 'default' | 'headers';
export type TextSize = 'default';

export interface TypographyBase {
  decoration?: TextDecoration;
  variant?: TextVariant;
}

export const typographyBaseCss = css<TypographyBase>`
  font-family: ${({ variant }) =>
    variant === 'headers' ? typography.fontFamilyHeaders : typography.fontFamilyDefault};
`;

export const textOverflow = (lines: number) => css`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};

  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
`;

export const textOverflowLines = (lines: number) => css`
  -webkit-line-clamp: ${lines};
`;

export const textUnderlineEffect = css<TypographyBase>`
  padding-top: 0.1875rem;
  position: relative;
  text-decoration: ${({ decoration }) => (decoration === 'underline' ? 'underline' : 'none')};

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:active {
    color: currentColor;
  }
`;

export const typographySizeConfig: Record<TextSize, ReturnType<typeof css>> = {
  default: css`
    font-size: 0.75rem;
  `,
};
