import React from 'react'
import { PageTransitionScrollFixer } from '../components/PageTransitionScrollFixer'
import { MutableDefaultAppExtensionsArray } from 'vendor/utils/MutableDefaultAppExtensionsArray'

/**
 * This list contains components that will wrap the whole application.
 * Every component put in the list will be applied in put order, eg.
 *
 * DefaultAppWrappers.push(AppendA)
 * DefaultAppWrappers.push(AppendB)
 *
 * will result in rendering:
 *
 * <DefaultAppWrappers>
 *   <AppendA/>
 *   <AppendB/>
 *   ...PageErrorHandler...
 *   <DefaultContentsWrappers>
 *     <PageComponent {...pageProps}>
 *   </DefaultContentsWrappers>
 * </WrapperB>
 */
export const DefaultAppAppends = new MutableDefaultAppExtensionsArray<React.FunctionComponent>(
  PageTransitionScrollFixer,
)
