import { CookieStorage, defaultCookieStorage } from 'src/common/utils/cookies';

export const deleteFromStorage = (
  queryKey: string,
  cookies: CookieStorage | null = defaultCookieStorage,
) => {
  if (!cookies) throw new Error('cookies storage not set');
  cookies.remove(queryKey);
};

export const readFromStorage = (
  queryKey: string,
  cookies: CookieStorage | null = defaultCookieStorage,
) => {
  if (!cookies) throw new Error('cookies storage not set');
  const data = cookies.get(queryKey);
  return data ? JSON.parse(data) : undefined;
};

export const writeToStorage = (
  queryKey: string,
  data: unknown,
  cookies: CookieStorage | null = defaultCookieStorage,
) => {
  if (!cookies) throw new Error('cookies storage not set');
  cookies.set(queryKey, JSON.stringify(data));
};
