import { mapToSpeaker, Speaker, SpeakerDTO } from 'src/common/models/speaker';
import { KeywordDTO } from 'src/common/models/keyword';
import { CourseEvent, EventDTO, mapToCourseEvent } from 'src/common/models/event';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { Category, CategoryDTO, mapToCategory } from 'src/common/models/category';
import { evolve, map } from 'ramda';
import { pipe } from 'fp-ts/function';
import { chainNullableK, fromNullable, map as mapO, match, some, toUndefined } from 'fp-ts/Option';
import { CourseType } from 'src/common/models/courseType';
import { getCourseEventSlugRoute, getCourseSlugRoute } from 'src/course/routes';
import { StoredImageDTO } from './image';

export interface LearningPointDTO {
  readonly id: number;
  value: string;
}

export interface TargetGroupDTO {
  readonly id: number;
  value: string;
}

export interface CourseDTO {
  readonly id: number;
  backupLimit: number;
  categories: CourseCategoryDTO[];
  comment?: string;
  description: string;
  events: EventDTO[];
  image: StoredImageDTO;
  introduction: string;
  keywords: KeywordDTO[];
  learningPoints: LearningPointDTO[];
  level: CourseLevel;
  mainLimit: number;
  recommendationPercent: number;
  speakers: SpeakerDTO[];
  surveyId?: number;
  targetGroups: TargetGroupDTO[];
  title: string;
}

export enum CourseLevel {
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

interface CourseProgramItem {
  text: string;
  time: string;
}

export interface CourseParameter {
  id: number;
  value: string;
}

export interface CourseCategoryDTO extends CategoryDTO {
  main: boolean;
}

export interface CourseCategory extends Category {
  main?: boolean;
}

export interface Course {
  readonly id: number;
  backupLimit: number;
  categories: CourseCategory[];
  comment?: string;
  description: string;
  events: CourseEvent[];
  image: StoredImageDTO;
  introduction: string;
  keywords: CourseParameter[];
  learningPoints: CourseParameter[];
  level: CourseLevel;
  mainLimit: number;
  recommendationPercent: number;
  speakers: Speaker[];
  surveyId?: number;
  targetGroups: CourseParameter[];
  title: string;
  slug?: string;

  // todo do usunięcia po integracji
  date?: Date;
  dates?: unknown[];
  imageCardUrl?: string;
  imageFeaturedCardUrl?: string;
  program?: CourseProgramItem[];
  speakerText?: string;
}

export const courseLevelLabelMap = {
  [CourseLevel.Beginner]: 'Początkujący',
  [CourseLevel.Intermediate]: 'Średnio zaawansowany',
  [CourseLevel.Advanced]: 'Zaawansowany',
};

export const courseLevels = [
  {
    id: CourseLevel.Beginner,
    label: courseLevelLabelMap[CourseLevel.Beginner],
  },
  {
    id: CourseLevel.Intermediate,
    label: courseLevelLabelMap[CourseLevel.Intermediate],
  },
  {
    id: CourseLevel.Advanced,
    label: courseLevelLabelMap[CourseLevel.Advanced],
  },
];

export const mapToCourse: (data: CourseDTO) => Course = evolve({
  surveyId: (v) => v?.toString(),
  events: map(mapToCourseEvent),
  categories: map(mapToCategory),
  speakers: map(mapToSpeaker),
});

export const mapToCourseExcerpt = (course: Course, eventId?: number): CourseExcerpt => ({
  comment: course.comment,
  image: course.image,
  title: course.title,
  slug: course.slug,
  courseId: course.id,
  courseType: CourseType.StandardCourse,

  elearningUserEnrollment: null,

  id: pipe(
    fromNullable(eventId),
    chainNullableK((b) => course.events.find((a) => a.id === b)),
    match(() => fromNullable(course.events[0]), some),
    mapO((a) => Number(a.id)),
    toUndefined,
  ),

  date: pipe(
    fromNullable(eventId),
    chainNullableK((b) => course.events.find((a) => a.id === b)),
    match(() => fromNullable(course.events[0]), some),
    mapO((a) => a.startDateTime),
    toUndefined,
  ),

  speaker: pipe(
    fromNullable(course.speakers[0]),
    mapO((a) => ({
      id: a.id,
      firstName: a.firstName,
      lastName: a.lastName,
      companyName: a.company,
    })),
    toUndefined,
  ),

  link: pipe(
    fromNullable(eventId),
    chainNullableK((b) => course.events.find((a) => a.id === b)),
    match(() => fromNullable(course.events[0]), some),
    match(
      () => getCourseSlugRoute(course),
      (event) =>
        getCourseEventSlugRoute(
          {
            id: event.id,
          },
          course,
        ),
    ),
  ),
});
