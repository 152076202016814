import { createReducer } from '@reduxjs/toolkit';
import { RegisterFormModel, RequestResetPasswordFormModel } from 'src/common/models/auth';
import { pageContextActions } from 'src/state/pageContext/pageContextActions';
import { omit } from 'ramda';
import {
  AuthCourseExcerptPayload,
  PageContextPayload,
} from 'src/state/pageContext/pageContextPayloads';

export enum PageContextType {
  AuthCourseExcerpt = 'authCourseExcerpt',
  RegisteredUserCache = 'registeredUserCache',
  RedirectUrl = 'redirectUrl',
  RequestResetPasswordFormCache = 'requestResetPasswordFormCache',
}

export interface PageContextState {
  [PageContextType.AuthCourseExcerpt]?: AuthCourseExcerptPayload;
  [PageContextType.RegisteredUserCache]?: RegisterFormModel;
  [PageContextType.RedirectUrl]?: PageContextPayload;
  [PageContextType.RequestResetPasswordFormCache]?: RequestResetPasswordFormModel;
}

const initialState: PageContextState = {};

export const pageContextReducer = createReducer<PageContextState>(initialState, (builder) =>
  builder
    .addCase(pageContextActions.cleanPageContext, (state, action) => omit([action.payload])(state))
    .addCase(pageContextActions.setAuthCourseExcerpt, (state, action) => ({
      ...state,
      [PageContextType.AuthCourseExcerpt]: action.payload,
    }))
    .addCase(pageContextActions.setRegisteredUserCache, (state, action) => ({
      ...state,
      [PageContextType.RegisteredUserCache]: action.payload,
    }))
    .addCase(pageContextActions.setRequestResetPasswordFormCache, (state, action) => ({
      ...state,
      [PageContextType.RequestResetPasswordFormCache]: action.payload,
    }))
    .addCase(pageContextActions.setRedirectUrl, (state, action) => ({
      ...state,
      [PageContextType.RedirectUrl]: action.payload,
    })),
);
