import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const WarningIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path d="M32 0C14.3551 0 0 14.3551 0 32s14.3551 32 32 32 32-14.3551 32-32S49.6449 0 32 0zm0 50.4066c-1.7946 0-3.2495-1.4549-3.2495-3.2495 0-1.7946 1.4549-3.2496 3.2495-3.2496 1.7946 0 3.2495 1.455 3.2495 3.2496S33.7946 50.4066 32 50.4066zm3.2495-16.6809c0 1.7946-1.4549 3.2495-3.2495 3.2495-1.7946 0-3.2495-1.4549-3.2495-3.2495V16.6116c0-1.7946 1.4549-3.2496 3.2495-3.2496 1.7946 0 3.2495 1.455 3.2495 3.2496v17.1141z" />
  </IconBox>
);

export default WarningIcon;
