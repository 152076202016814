import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

/**
 * https://github.com/rt2zz/redux-persist/issues/1208#issuecomment-658695446
 * Workaround for error message regarding using NOOP storage during build.
 * this proxy WILL ALSO USE NOOP STORAGE
 */
const createNoopStorage = () => ({
  getItem: () => Promise.resolve(null),
  setItem: (_key: unknown, value: unknown) => Promise.resolve(value),
  removeItem: () => Promise.resolve(),
});

export const storage =
  typeof window === 'undefined' ? createNoopStorage() : createWebStorage('local');
