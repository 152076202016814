export enum ErrorCode {
  AccessDenied = 'access_denied',
  AvatarImgSize = 'avatarImgSize',
  BadCredentials = 'invalid_grant',
  CourseNotExist = 'COURSE.NOT_EXIST',
  EventAlreadyJoined = 'EVENT.USER_ALREADY_JOINED',
  EmailNotFoundCode = 'UME.ENF',
  AvatarUnsupportedFormat = 'avatarUnsupportedFormat',
  UnauthorizedUser = 'unauthorized',
  LoginExists = 'error.user.login.exists',
  TokenExpired = 'error.user.token.expired',
  TokenExpiredCode = 'UME.TOE',
  NewPasswordEqualsOld = 'UME.OLD_PASS_EQUALS_NEW',
  WrongToken = 'UME.WRT',
  Unknown = 'unknown',
  UnexpectedRequest = 'unexpected',
  Internal = 'internal',
}

export const errorMsgs: Record<ErrorCode | string, string> = {
  [ErrorCode.AccessDenied]: 'Dostęp wzbroniony',
  [ErrorCode.AvatarImgSize]: 'Rozmiar pliku jest zbyt duży (max 1024KB)',
  [ErrorCode.AvatarUnsupportedFormat]:
    'Nieprawidłowy format pliku, obsługiwane formaty to: .jpg, .jpeg oraz .png',
  [ErrorCode.BadCredentials]: 'Niewłaściwe dane logowania',
  [ErrorCode.CourseNotExist]: 'Szkolenie o podanym ID nie istnieje',
  [ErrorCode.EventAlreadyJoined]: 'Jesteś już zapisany(-na) na szkolenie',
  [ErrorCode.EmailNotFoundCode]: 'Nie znaleziono podanego adresu email',
  [ErrorCode.Internal]: 'Wystąpił błąd wewnętrzny',
  [ErrorCode.LoginExists]: 'Konto o podanym adresie e-mail już istnieje',
  [ErrorCode.NewPasswordEqualsOld]: 'Nowe hasło musi różnić się od starego',
  [ErrorCode.TokenExpired]: 'Przekroczono czas oczekiwania',
  [ErrorCode.TokenExpiredCode]: 'Wygasł token zmiany hasła',
  [ErrorCode.Unknown]: 'Wystąpił nieznany błąd',
  [ErrorCode.UnexpectedRequest]: 'Wystąpił nieznany błąd połączenia',
  [ErrorCode.UnauthorizedUser]: 'Użytkownik nie został potwierdzony',
  [ErrorCode.WrongToken]: 'Niewłaściwy token',
};
