import React, { FC } from 'react';
import Head from 'next/head';
import getConfig from 'next/config';

const config = getConfig().publicRuntimeConfig;

export const TrackingScripts: FC = () => {
  if (config.app.env === 'production') {
    return (
      <Head>
        <script
          key="TrackingScripts"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MJQ7F33');
        `,
          }}
        />
      </Head>
    );
  }

  return null;
};
