import CategoryYourCoursesIcon from 'src/assets/icons/CategoryYourCoursesIcon';
import { FC } from 'react';
import { IconProps } from 'src/lib/IconBox';
import { courseCategoryIconMap } from 'src/config/icons';
import EmptyIcon from 'src/assets/icons/EmptyIcon';
import { pipe } from 'fp-ts/function';
import { findFirst } from 'fp-ts/Array';
import { fold } from 'fp-ts/Option';
import { StoredImageDTO } from './image';

export interface CategoryDTO {
  id: number;
  label: string;
  code: string;
  isSpecialCategory: boolean;
  specialCategoryData?: SpecialCategoryDataDTO;
  slug: string;
}

export interface SpecialCategoryDataDTO {
  partnerLogo: StoredImageDTO;
  partnerName: string;
  programName: string;
  usesSpecialCertificateTemplate: boolean;
}

export interface Category {
  id: number;
  title: string;
  code: string;
  icon: FC<IconProps>;
  isSpecialCategory: boolean;
  slug: string;
}

export interface MainCategory extends Category {
  mobileTitle: string;
}

export const entrepreneurCategoryCode = 'category-company-management';

export const mainCategory: MainCategory = {
  id: -1,
  mobileTitle: 'Moje szkolenia',
  title: 'Moje<br />szkolenia',
  code: 'category-your-courses',
  icon: CategoryYourCoursesIcon,
  isSpecialCategory: false,
  slug: 'moje-szkolenia',
};

export const getCategoryIdByCode = (categories: Category[], code: string) =>
  pipe(
    categories,
    findFirst<Category>((item) => item.code === code),
    fold<Category, number | null>(
      () => null,
      (item) => item.id,
    ),
  );

export const mapToCategory: (dto: CategoryDTO) => Category = ({
  id,
  label,
  code,
  isSpecialCategory,
  slug,
}) => ({
  title: label,
  icon: courseCategoryIconMap[code] ?? EmptyIcon,
  code,
  id,
  isSpecialCategory,
  slug,
});
