import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CategoryCompanyManagementIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M40.4996 3.2c0-1.76731 1.4327-3.2 3.2-3.2h16c1.7673 0 3.2 1.43269 3.2 3.2v16c0 1.7673-1.4327 3.2-3.2 3.2s-3.2-1.4327-3.2-3.2V6.4h-12.8c-1.7673 0-3.2-1.43269-3.2-3.2z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M61.9624.93726c1.2496 1.24968 1.2496 3.2758 0 4.52548L7.56235 59.8627c-1.24968 1.2497-3.2758 1.2497-4.52548 0-1.24968-1.2496-1.24968-3.2758 0-4.5254L57.4369.93726c1.2497-1.24968 3.2758-1.24968 4.5255 0z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M59.6996 41.6c1.7673 0 3.2 1.4327 3.2 3.2v16c0 1.7673-1.4327 3.2-3.2 3.2h-16c-1.7673 0-3.2-1.4327-3.2-3.2s1.4327-3.2 3.2-3.2h12.8V44.8c0-1.7673 1.4327-3.2 3.2-3.2z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M38.2369 39.3373c1.2496-1.2497 3.2758-1.2497 4.5255 0l19.2 19.2c1.2496 1.2496 1.2496 3.2758 0 4.5254-1.2497 1.2497-3.2758 1.2497-4.5255 0l-19.2-19.2c-1.2497-1.2496-1.2497-3.2758 0-4.5254z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M3.03687 4.13726c1.24968-1.24968 3.2758-1.24968 4.52548 0L23.5624 20.1373c1.2496 1.2496 1.2496 3.2758 0 4.5254-1.2497 1.2497-3.2759 1.2497-4.5255 0L3.03687 8.66274c-1.24968-1.24968-1.24968-3.2758 0-4.52548z"
    />
  </IconBox>
);

export default CategoryCompanyManagementIcon;
