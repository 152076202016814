import { createReducer } from '@reduxjs/toolkit';
import {
  areAllSelected,
  CourseMaterialsFilters,
  courseMaterialsFiltersInitialValues,
  CourseMaterialsSelectedMap,
  toAvailableOnlyArray,
} from 'src/common/models/courseMaterial';
import { userMaterialsActions } from 'src/user/materials/state/userMaterials/userMaterialsActions';
import { keys } from 'ramda';

export interface UserMaterialsState {
  courseFilters: CourseMaterialsFilters;
  items: CourseMaterialsSelectedMap;
  showFiltersModal: boolean;
  total: number;
}

const initialState: UserMaterialsState = {
  courseFilters: courseMaterialsFiltersInitialValues,
  items: {},
  showFiltersModal: false,
  total: 0,
};

export const userMaterialsReducer = createReducer<UserMaterialsState>(initialState, (builder) =>
  builder
    .addCase(userMaterialsActions.addMaterials, (state, action) => {
      action.payload.forEach((course) =>
        course.materials.forEach((material) => {
          state.items[material.id] = {
            available: course.available,
            filename: material.filename,
            id: material.id,
            selected: false,
            type: material.type,
          };
        }),
      );
    })
    .addCase(userMaterialsActions.clear, () => initialState)
    .addCase(userMaterialsActions.deselectAllMaterials, (state) => {
      const available = keys(toAvailableOnlyArray(state.items));

      available.forEach((key) => {
        state.items[key].selected = false;
      });
    })
    .addCase(userMaterialsActions.setCourseFilters, (state, action) => {
      state.courseFilters = action.payload;
    })
    .addCase(userMaterialsActions.setMaterials, (state, action) => {
      state.items = {};

      action.payload.forEach((course) =>
        course.materials.forEach((material) => {
          state.items[material.id] = {
            available: course.available,
            filename: material.filename,
            id: material.id,
            selected: false,
            type: material.type,
          };
        }),
      );
    })
    .addCase(userMaterialsActions.setTotal, (state, action) => {
      state.total = action.payload;
    })
    .addCase(userMaterialsActions.selectAllAvailableMaterials, (state) => {
      const allSelected = areAllSelected(state.items);
      const available = keys(toAvailableOnlyArray(state.items));

      if (allSelected) {
        available.forEach((key) => {
          state.items[key].selected = false;
        });
        return;
      }

      available.forEach((key) => {
        state.items[key].selected = true;
      });
    })
    .addCase(userMaterialsActions.toggleFiltersModal, (state, action) => {
      state.showFiltersModal = action.payload;
    })
    .addCase(userMaterialsActions.toggleMaterial, (state, action) => {
      state.items[action.payload.id].selected = action.payload.selected;
    }),
);
