import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  ApiError,
  ErrorResponse,
  FlatError,
  FlatErrorResponse,
  isAxiosError,
} from 'src/common/models/error';
import { ErrorCode, errorMsgs } from 'src/common/services/errors';
import getConfig from 'next/config';
import { captureAxiosException } from 'src/common/services/sentry';
import HttpAgent, { HttpsAgent } from 'agentkeepalive';

const publicConfig = getConfig().publicRuntimeConfig;

const createAxios = (options: AxiosRequestConfig) => {
  const axiosOptions: AxiosRequestConfig = {
    ...options,
  };

  if (publicConfig.httpAgent.enabled) {
    axiosOptions.timeout = publicConfig.httpAgent.timeout;
    axiosOptions.transitional = {
      clarifyTimeoutError: true,
    };

    if (typeof window === 'undefined') {
      axiosOptions.httpAgent = new HttpAgent(publicConfig.httpAgent);
      axiosOptions.httpsAgent = new HttpsAgent(publicConfig.httpAgent);
    }
  }

  return Axios.create(axiosOptions);
};

// ------------------- http clients

export const BaseAxios = createAxios({
  baseURL: publicConfig.api.url,
  headers: {
    Authorization: `Basic ${publicConfig.auth.basicAuth}`,
    'X-client-name': publicConfig.app.name,
    'X-client-version': publicConfig.app.version,
  },
});

export const CmsAxios = createAxios({
  baseURL: publicConfig.cms.url,
});

export const AuthorizedAxios = createAxios({
  baseURL: publicConfig.api.url,
  headers: {
    'X-client-name': publicConfig.app.name,
    'X-client-version': publicConfig.app.version,
  },
});

export const MixedAxios = createAxios({
  baseURL: publicConfig.api.url,
  headers: {
    Authorization: `Basic ${publicConfig.auth.basicAuth}`,
    'X-client-name': publicConfig.app.name,
    'X-client-version': publicConfig.app.version,
  },
});

// ------------------- error handling
//
// // https://github.com/axios/axios#handling-errors
export const toErrorResponse = (
  error: AxiosError<ApiError | FlatError>,
): ErrorResponse | FlatErrorResponse => {
  let response;

  if (isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      response = {
        ...error.response.data,
        status: error.response.status,
      };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      response = {
        errors: [
          {
            data: error.request,
            errorCode: '-1',
            message: errorMsgs[ErrorCode.UnexpectedRequest],
          },
        ],
        status: -1,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      response = {
        errors: [
          {
            data: error.message,
            errorCode: '-1',
            message: errorMsgs[ErrorCode.Internal],
          },
        ],
        status: -1,
      };
    }
  } else {
    response = {
      errors: [{ data: error, errorCode: '-1', message: errorMsgs[ErrorCode.Unknown] }],
      status: -1,
    };
  }

  captureAxiosException(error, response);
  return response;
};

export const throwErrorResponse = (error: AxiosError<ApiError | FlatError>) => {
  throw toErrorResponse(error);
};
