import { is, keys, omit } from 'ramda';
import { UrlObject } from 'url';
import { stringify } from 'query-string';

export const buildRedirectUrl = (url: string, ctx: VendorServerSideContext): string => {
  const queryParams = omit(keys(ctx.params), ctx.query);
  const queryString = stringify(queryParams);

  return queryString.length > 0 ? `${url}?${queryString}` : url;
};

export const buildPathname = (segments: string[]): string =>
  ['', ...segments]
    .map((segment) => (segment.startsWith(':') ? `[${segment.substring(1)}]` : segment))
    .join('/');

export const buildRoute = (segments: string[]): string => ['', ...segments].join('/');

export const pageParam = (param: string | string[] | undefined): string | undefined =>
  Array.isArray(param) ? param[0] : param;

export const setParam =
  (param: string) =>
  (route: string) =>
  (value: string): string =>
    route.replace(param, value);

export const translateSegments = (
  segments: string[],
  translations: Record<string, string>,
): string[] =>
  segments.map((segment) =>
    segment.startsWith('[') || segment.startsWith(':') || !translations[segment]
      ? segment
      : translations[segment],
  );

export const buildAsUrl = (url: UrlObject): UrlObject => {
  if (!url.pathname) {
    return {};
  }

  if (!url.query) {
    return {
      pathname: url.pathname,
    };
  }

  if (typeof url.query === 'string') {
    return {
      pathname: url.pathname,
      query: url.query,
    };
  }

  const excludedParams = ['categoryId', '_vendor'];

  const paramKeys = keys(url.query).map((key) => String(key));
  const queryParams = {
    ...url.query,
  };

  return {
    pathname: is(Object, queryParams)
      ? paramKeys.reduce((curr, param) => {
          if (excludedParams.includes(param)) {
            delete queryParams[param];
            return curr;
          }

          if (!curr.includes(`[${param}]`) || !queryParams[param]) {
            return curr;
          }

          const next = curr.replace(`[${param}]`, String(queryParams[param]));
          delete queryParams[param];

          return next;
        }, url.pathname)
      : url.pathname,
    query: queryParams,
  };
};
