import { AxiosRequestConfig } from 'axios';
import { isPast, parseISO } from 'date-fns';
import { Auth } from 'src/common/models/auth';
import { refreshToken } from 'src/common/services/auth';
import { memoize } from 'lodash-es';

export interface RefreshTokenInterceptorProps {
  getAuthData: () => Auth | undefined;
  onRefreshSuccess: (auth: Auth) => Promise<void>;
  onRefreshError: (e: unknown) => Promise<void>;
}

const memoizedRefreshToken = memoize(refreshToken);

export const createRefreshTokenInterceptor =
  ({ getAuthData, onRefreshSuccess, onRefreshError }: RefreshTokenInterceptorProps) =>
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const auth = getAuthData();

    if (auth == null) {
      return config;
    }

    if (auth.expires_at == null || isPast(parseISO(auth.expires_at))) {
      await memoizedRefreshToken(auth.refresh_token).then(onRefreshSuccess).catch(onRefreshError);
    }

    return config;
  };
