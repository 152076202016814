import { FC, useMemo } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { DefaultAppWrappers } from 'vendor/utils/DefaultAppWrappers';
import { useIsServer } from 'vendor/hooks/useIsServer';
import { axiosInterceptorsBind } from 'src/extensions/client/reactQuery/axiosInterceptorsBind';
import { localStorageRehydrate } from 'src/extensions/client/reactQuery/localStorageRehydrate';
import { useDispatch } from 'react-redux';

type ReactQueryRewirePlugin = (queryClient: QueryClient, deps: Record<any, any>) => void;
const plugins: ReactQueryRewirePlugin[] = [axiosInterceptorsBind, localStorageRehydrate];

const ReactQueryRewire: FC = ({ children }) => {
  const isServer = useIsServer();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  useMemo(() => {
    if (isServer) {
      return;
    }
    plugins.forEach((fn) => fn(queryClient, { dispatch }));
  }, [isServer]);

  return <>{children}</>;
};

DefaultAppWrappers.push(ReactQueryRewire);
