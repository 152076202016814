import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CategoryMarketingIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M63.219 14.1159c1.0414 1.0413 1.0414 2.7296 0 3.7709L37.8856 43.2179c-1.0414 1.0413-2.7298 1.0413-3.7712 0L22.6667 31.7712 4.55229 49.884c-1.0414 1.0413-2.72984 1.0413-3.77124 0-1.0414-1.0413-1.0414-2.7296 0-3.7709L20.781 26.1149c1.0414-1.0413 2.7299-1.0413 3.7713 0L36 37.5616l23.4477-23.4457c1.0414-1.0413 2.7299-1.0413 3.7713 0z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M42.6667 16.0014c0-1.4726 1.1939-2.6664 2.6666-2.6664h16c1.4728 0 2.6667 1.1938 2.6667 2.6664V32c0 1.4726-1.1939 2.6664-2.6667 2.6664-1.4727 0-2.6666-1.1938-2.6666-2.6664V18.6678H45.3333c-1.4727 0-2.6666-1.1938-2.6666-2.6664z"
    />
  </IconBox>
);

export default CategoryMarketingIcon;
