import Cookies from 'cookies';
import clientCookies from 'js-cookie';

export interface CookieStorage {
  /**
   * Create a cookie
   */
  set(
    name: string,
    value: string,
    options?: {
      expires?: Date;
      secure?: true;
      httpOnly?: boolean;
    },
  ): unknown | void;

  /**
   * Read cookie
   */
  get(name: string): string | undefined;

  /**
   * Delete cookie
   */
  remove(name: string): void;
}

export const defaultCookieStorage = typeof window !== 'undefined' ? clientCookies : null;

export class SSRCookies extends Cookies implements CookieStorage {
  remove(name: string): void {
    super.set(name);
  }

  get(name: string, opts?: Cookies.GetOption): string | undefined {
    const rawValue = super.get(name, opts);
    return rawValue && decodeURIComponent(rawValue);
  }
}
