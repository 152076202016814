import { applyMiddleware, combineReducers, legacy_createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { createRootReducer, RootState } from 'src/state/reducer';
import { createReducerInjector, createSagaInjector } from 'src/lib/reduxInjectors';
import * as Sentry from '@sentry/react';

function* rootSaga() {
  yield all([]);
}

export const rootStateFactory = () => {
  const isServer = typeof window === 'undefined';
  const sagaMiddleware = createSagaMiddleware();
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();
  const { injectSaga, deleteSaga } = createSagaInjector(sagaMiddleware.run);
  const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware), sentryReduxEnhancer);

  const rootReducer = createRootReducer();

  let store: Store<RootState>;
  if (isServer) {
    store = legacy_createStore(combineReducers<RootState>(rootReducer), enhancer);
  } else {
    store = legacy_createStore(combineReducers<RootState>(rootReducer), enhancer);
    persistStore(store);
  }
  const { injectReducer, deleteReducer } = createReducerInjector(rootReducer, store.replaceReducer);
  injectSaga('root', rootSaga);

  return {
    store,
    injectReducer,
    deleteReducer,
    injectSaga,
    deleteSaga,
  };
};

export const { store, injectReducer, deleteReducer, injectSaga, deleteSaga } = rootStateFactory();
