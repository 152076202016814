import { rem } from 'polished';
import { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

const fontSizeBase = 12;
const fontFamilyDefault = '"Open Sans", sans-serif';
const fontFamilyHeaders = '"Slabo 13px", serif;';
const fontFamilyRoboto = '"Roboto", sans-serif;';

export const typography = {
  fontSizeBase: rem(fontSizeBase),
  fontFamilyDefault,
  fontFamilyHeaders,
  fontFamilyRoboto,
} as const;

export const heading = {
  h1: css`
    font-style: normal;
    font-weight: normal;
    font-family: ${typography.fontFamilyHeaders};

    font-size: 1.5rem;
    line-height: 1.875rem;

    ${media.w.min.px768(css`
      font-size: 2.3125rem;
      line-height: 2.875rem;
    `)}
  `,
};
