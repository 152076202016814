import { AxiosError } from 'axios';
import { Auth } from 'src/common/models/auth';
import { pipe } from 'fp-ts/lib/function';
import { chainNullableK, fold, fromNullable } from 'fp-ts/lib/Option';

export interface ResponseErrorInterceptorProps {
  getAuthData: () => Auth | undefined;
  on401Response: () => Promise<void>;
}

export const createResponseErrorInterceptor =
  ({ on401Response }: ResponseErrorInterceptorProps) =>
  async (axiosError: AxiosError): Promise<AxiosError> => {
    return pipe(
      fromNullable(axiosError),
      chainNullableK((_: any) => _.response),
      fold(
        () => Promise.reject(axiosError),
        async (e: any) => {
          if (e.status === 401) {
            await on401Response();
          }

          return Promise.reject(axiosError);
        },
      ),
    );
  };
