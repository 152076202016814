import { useQuery } from 'react-query';
import { readFromStorage } from 'src/common/services/queryStorage';
import { QueryKey } from 'src/config/queryKey';

export const useAuthQuery = () =>
  useQuery(QueryKey.Auth, () => readFromStorage('auth'), {
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
