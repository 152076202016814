import { CategoryDTO, mapToCategory } from 'src/common/models/category';
import { BaseAxios, MixedAxios, throwErrorResponse } from 'src/common/services/axios';
import { CourseDTO, mapToCourse, mapToCourseExcerpt } from 'src/common/models/course';
import { flatten } from 'src/lib/axios';
import { map } from 'ramda';
import { relatedCoursesLimit } from 'src/config/limits';
import { PageResultDTO, unwrap as unwrapPage } from 'src/common/models/page';
import { format, isToday, isTomorrow } from 'date-fns';
import React from 'react';
import { ListWrapper, unwrap } from 'src/lib/listWrapper';

export function getCourseCategoriesDTO() {
  return BaseAxios.get<PageResultDTO<CategoryDTO>>(`/api/courses/parameters/categories/category`, {
    params: { pageSize: 30 },
  })
    .then(flatten)
    .then(unwrapPage);
}

export const getCourseCategories = () => getCourseCategoriesDTO().then(map(mapToCategory));

export const getAvailableCourses = (
  isLoggedIn: boolean,
  limit: number,
  filters?: { categoryId?: number; speakerId?: number },
) => {
  const request = isLoggedIn
    ? MixedAxios.get<ListWrapper<CourseDTO>>(`/api/courses/available`, {
        params: { limit, ...filters },
      })
    : BaseAxios.get<ListWrapper<CourseDTO>>(`/api/public/courses/available`, {
        params: { limit, ...filters },
      });

  return request.then(flatten).then(unwrap).then(map(mapToCourse)).catch(throwErrorResponse);
};

export function getCourseDTOById(id: number) {
  return BaseAxios.get<CourseDTO>(`/api/courses/${id}`).then(flatten).catch(throwErrorResponse);
}

export const getCourseById = (id: number) => getCourseDTOById(id).then(mapToCourse);

export const getRelatedCourses = (courseId: number, isLoggedIn: boolean) => {
  const request = isLoggedIn
    ? MixedAxios.get<ListWrapper<CourseDTO>>(`/api/courses/${courseId}/related/standard`, {
        params: { limit: relatedCoursesLimit },
      })
    : BaseAxios.get<ListWrapper<CourseDTO>>(`/api/public/courses/${courseId}/related/standard`, {
        params: { limit: relatedCoursesLimit },
      });

  return request.then(flatten).then(unwrap).then(map(mapToCourse)).then(map(mapToCourseExcerpt));
};

export const formatCourseCalendarDate = (a: Date) => {
  if (isToday(a)) {
    return <>Dzisiaj &bull; {format(a, 'HH:mm')}</>;
  }
  if (isTomorrow(a)) {
    return <>Jutro &bull; {format(a, 'HH:mm')}</>;
  }

  return (
    <>
      {format(a, 'dd.LL.uuuu')} &bull; {format(a, 'HH:mm')}
    </>
  );
};
