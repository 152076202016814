import { createAction } from 'src/lib/createAction';
import { CourseMaterialsDTO, CourseMaterialsFilters } from 'src/common/models/courseMaterial';

export interface UserMaterialsToggleMaterialPayload {
  id: number;
  selected: boolean;
}

export const userMaterialsActions = {
  addMaterials: createAction('user/materials/addMaterials')<CourseMaterialsDTO[]>(),
  clear: createAction('user/materials/clear')(),
  deselectAllMaterials: createAction('user/materials/deselectAllMaterials')(),
  selectAllAvailableMaterials: createAction('user/materials/selectAllAvailableMaterials')(),
  setCourseFilters: createAction('user/materials/setCourseFilters')<CourseMaterialsFilters>(),
  setMaterials: createAction('user/materials/setMaterials')<CourseMaterialsDTO[]>(),
  setTotal: createAction('user/materials/setTotal')<number>(),
  toggleFiltersModal: createAction('user/materials/toggleFiltersModal')<boolean>(),
  toggleMaterial: createAction(
    'user/materials/toggleMaterial',
  )<UserMaterialsToggleMaterialPayload>(),
};
