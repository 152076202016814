import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CategoryYourCoursesIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0 3.19996C0 1.43267 1.43267 0 3.19996 0H25.5997c1.7672 0 3.1999 1.43267 3.1999 3.19996V25.5997c0 1.7672-1.4327 3.1999-3.1999 3.1999H3.19996C1.43267 28.7996 0 27.3669 0 25.5997V3.19996zm6.39991 3.19995V22.3997H22.3997V6.39991H6.39991z"
    />
    <path
      fill="#B71833"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.2012 14.3998C35.2012 6.44701 41.6482 0 49.601 0c7.9528 0 14.3998 6.44701 14.3998 14.3998 0 7.9528-6.447 14.3998-14.3998 14.3998-7.9528 0-14.3998-6.447-14.3998-14.3998zM49.601 6.39991c-4.4182 0-7.9999 3.58168-7.9999 7.99989 0 4.4182 3.5817 7.9999 7.9999 7.9999 4.4182 0 7.9999-3.5817 7.9999-7.9999 0-4.41821-3.5817-7.99989-7.9999-7.99989z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M35.2012 38.3992c0-1.7673 1.4326-3.2 3.1999-3.2h22.3997c1.7673 0 3.2 1.4327 3.2 3.2v22.3997c0 1.7673-1.4327 3.1999-3.2 3.1999H38.4011c-1.7673 0-3.1999-1.4326-3.1999-3.1999V38.3992zm6.3999 3.1999v15.9998h15.9998V41.5991H41.6011z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0 38.3992c0-1.7673 1.43267-3.2 3.19996-3.2H25.5997c1.7672 0 3.1999 1.4327 3.1999 3.2v22.3997c0 1.7673-1.4327 3.1999-3.1999 3.1999H3.19996C1.43267 63.9988 0 62.5662 0 60.7989V38.3992zm6.39991 3.1999v15.9998H22.3997V41.5991H6.39991z"
    />
  </IconBox>
);

export default CategoryYourCoursesIcon;
