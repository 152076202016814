import { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { textUnderlineEffect, typographySizeConfig } from 'src/common/styles/typography';
import { rem } from 'polished';

export type ButtonKind = 'border' | 'light' | 'link' | 'primary' | 'secondary';
export type ButtonSize = 'default' | 'small' | 'tiny';

export const buttonKindConfig: Record<ButtonKind, ReturnType<typeof css>> = {
  border: css`
    background: ${colors.white};
    border: 0.125rem solid ${colors.darkGrey};
    border-radius: 0.25rem;
    color: ${colors.blue};
    font-weight: 600;
    line-height: 1rem;
    text-align: center;

    &:active:not(:disabled) {
      background: ${colors.darkGrey};
      color: ${colors.red};
      outline: none;
    }

    &:disabled {
      background: ${colors.grey08};
      opacity: 0.5;
    }

    &:hover {
      color: ${colors.red};
    }
  `,
  light: css`
    background: ${colors.darkGrey};
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    color: ${colors.blue};
    font-weight: 600;
    line-height: 1rem;
    text-align: center;

    &:active:not(:disabled) {
      background: ${colors.white};
      border-color: ${colors.darkGrey};
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
    }
  `,
  link: css`
    color: currentColor;
    line-height: 1rem;
    white-space: normal;

    &:active:not(:disabled) {
      outline: none;
    }

    &:disabled {
      cursor: default;
    }

    &:hover:not(:disabled) {
      box-shadow: none;
    }

    &:not(:disabled) {
      ${textUnderlineEffect};
    }
  `,
  primary: css`
    background: ${colors.red};
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 600;
    line-height: 1rem;
    text-align: center;

    &:active:not(:disabled) {
      background: ${colors.blue};
      outline: none;
    }

    &:disabled {
      background: ${colors.blue05};
      opacity: 0.5;
    }
  `,
  secondary: css`
    background: ${colors.blue};
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 600;
    line-height: 1rem;
    text-align: center;

    &:active:not(:disabled) {
      background: ${colors.red};
      outline: none;
    }

    &:disabled {
      background: ${colors.blue05};
      opacity: 0.5;
    }
  `,
} as const;

export const buttonKindLoadingConfig: Record<ButtonKind, ReturnType<typeof css>> = {
  border: css`
    &:disabled {
      background: ${colors.white};
      opacity: 1;
    }
  `,
  light: css`
    &:disabled {
      opacity: 1;
    }
  `,
  link: css``,
  primary: css`
    &:disabled {
      background: ${colors.red025};
      opacity: 1;
    }
  `,
  secondary: css`
    background: ${colors.blue};
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 600;
    line-height: 1rem;
    text-align: center;

    &:active:not(:disabled) {
      background: ${colors.red};
      outline: none;
    }

    &:disabled {
      background: ${colors.blue025};
      opacity: 1;
    }
  `,
} as const;

export const buttonSizeConfig: Record<ButtonSize, ReturnType<typeof css>> = {
  default: typographySizeConfig.default,
  small: typographySizeConfig.default,
  tiny: typographySizeConfig.default,
} as const;

export const buttonSpacingConfig: Record<ButtonSize, ReturnType<typeof css>> = {
  default: css`
    padding: 0.4375rem 1.125rem;
  `,
  small: css`
    padding: 0.375rem 0.8125rem 0.3125rem;
  `,
  tiny: css`
    border-radius: 0.1875rem;
    font-weight: 400;
    padding: 0.0625rem 0.8125rem;
  `,
} as const;

export const buttonLoadingSizeConfig: Record<ButtonSize, number> = {
  default: 22,
  small: 20,
  tiny: 16,
} as const;

export const buttonSpacingLoadingConfig: Record<ButtonSize, ReturnType<typeof css>> = {
  default: css`
    padding-left: ${rem(23 + buttonLoadingSizeConfig.default / 2)};
    padding-right: ${rem(23 + buttonLoadingSizeConfig.default / 2)};
  `,
  small: css`
    padding-left: ${rem(18 + buttonLoadingSizeConfig.small / 2)};
    padding-right: ${rem(18 + buttonLoadingSizeConfig.small / 2)};
  `,
  tiny: css`
    padding-left: ${rem(18 + buttonLoadingSizeConfig.tiny / 2)};
    padding-right: ${rem(18 + buttonLoadingSizeConfig.tiny / 2)};
  `,
} as const;
