import { useQuery } from 'react-query';
import { getLoggedInUser } from 'src/common/services/user';
import { useAuthQuery } from 'src/common/hooks/useAuthQuery';
import { QueryKey } from 'src/config/queryKey';

export const useUserQuery = () => {
  const { data } = useAuthQuery();

  return useQuery(QueryKey.User, getLoggedInUser, {
    cacheTime: Infinity,
    enabled: data != null,
    staleTime: Infinity,
  });
};
