import React, { FC } from 'react';
import { siteMetadata } from 'src/config/metadata';
import Head from 'next/head';

export interface SeoProps {
  description?: string;
  keywords?: string;
  lang?: string;
  meta?: Array<{ name: string; content: string }>;
  title?: string;
}

export const Seo: FC<SeoProps> = ({ description, keywords, title }) => {
  const metaDescription = description || siteMetadata.description;
  const metaTitle = title ? `${title} | ${siteMetadata.title}` : siteMetadata.title;

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
    </Head>
  );
};
