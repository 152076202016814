import { GetStaticPropsResult } from 'next'

/**
 * This global object allows overwriting props generated by previous steps.
 * It allows you to extend every SSR request with additional functionality like cookie parsing or getting user session.
 *
 * Functions added to this list will run after resolving data from VendorGetServerSideProps parameter.
 * Please check {VendorGetServerSideProps} for complete list of steps performed.
 *
 * Each extension MUST return complete {GetStaticPropsResult} object.
 *
 * These extensions will run ONLY in SSG pages.
 *
 * This object can only be used in SSG extensions files:
 * vendor/mods/*\/ssg.ts
 * extensions/ssg/*.ts
 * src/extensions/ssg/*.ts
 */
export const GlobalStaticPropsDecorators: Array<
  <T>(
    props: GetStaticPropsResult<T>,
    ctx: VendorStaticContext,
  ) => GetStaticPropsResult<T> | Promise<GetStaticPropsResult<T>>
> = []

export async function decorateWithStaticPropsDecorators(
  returnProps: any,
  vendorContext: VendorStaticContext,
) {
  for (const decorator of GlobalStaticPropsDecorators) {
    returnProps = await decorator(returnProps, vendorContext)
  }
  return returnProps
}
