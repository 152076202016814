import { WrapperProps } from 'next-redux-wrapper'
import { AppProps } from 'next/app'
import React from 'react'
import 'vendor/generated-client-mods-loader'
import { wrapper } from 'vendor/store/configure-store'
import { AppType } from 'next/dist/shared/lib/utils'

if (typeof window === 'undefined') {
  require('vendor/generated-ssg-mods-loader')
}

export const VendorApp = (
  Component: React.ComponentType<AppProps>,
): AppType & React.ComponentType<WrapperProps> => {
  return wrapper.withRedux(Component) as unknown as AppType & React.ComponentType<WrapperProps>
}
