import * as Sentry from '@sentry/nextjs';
import { isAxiosError, isErrorResponse, isFlatErrorResponse } from 'src/common/models/error';
import { errorMsgs } from 'src/common/services/errors';
import { Context } from '@sentry/types';
import { AxiosError } from 'axios';

const blockedClientErrors = [401, 404];

export const captureException = (message: string, originalError: unknown, context: Context) => {
  if (
    isAxiosError(originalError) &&
    originalError.response &&
    blockedClientErrors.includes(originalError.response?.status)
  ) {
    return;
  }

  Sentry.withScope((scope) => {
    scope.setFingerprint([
      message,
      (isAxiosError(originalError) && originalError.response?.status.toString()) || 'undefined',
    ]);
    scope.setLevel('error');

    Sentry.captureException(originalError, {
      contexts: {
        'Error Details': context,
      },
    });
  });
};

export const captureAxiosException = (originalError: AxiosError, response: unknown) => {
  if (isErrorResponse(response)) {
    response.errors.forEach((error) => {
      const message = errorMsgs[error.errorCode] || error.message;

      captureException(message, originalError, {
        errorMessage: message,
        response,
        originalError,
      });
    });
  } else if (isFlatErrorResponse(response)) {
    const message = errorMsgs[response.error] || `${response.error}: ${response.error_description}`;

    captureException(message, originalError, {
      errorMessage: message,
      response,
      originalError,
    });
  } else {
    const message = errorMsgs.unknown;

    captureException(message, originalError, {
      errorMessage: message,
      response,
      originalError,
    });
  }
};
