import { useQuery } from 'react-query';
import { getPartner } from 'src/common/services/partners';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { QueryKey } from 'src/config/queryKey';
import { UseQueryOptions } from 'react-query/types/react/types';
import { PartnerDTO } from 'src/common/models/partner';

// has to be typed like this, otherwise there are weird issues with unknown return type ( depending queries and usage)
// when passing on success
export const usePartnerQuery = (
  code?: string,
  options?: Pick<
    UseQueryOptions<PartnerDTO | undefined, unknown, PartnerDTO | undefined, QueryKey>,
    'enabled' | 'onSuccess' | 'retry'
  >,
) => {
  const { key } = useCurrentUserKeyEnhancer([QueryKey.Partner, code]);

  return useQuery(key, () => (code ? getPartner(code) : undefined), {
    enabled: !!code && (options?.enabled ?? true),
    staleTime: Infinity,
    onSuccess: options?.onSuccess,
    retry: options?.retry,
  });
};
