import { createReducer } from '@reduxjs/toolkit';
import { tutorialActions } from 'src/state/tutorial/tutorialActions';

export interface TutorialState {
  currentStep: number;
  hasApp: boolean;
  isMobile: boolean;
}

const initialState: TutorialState = {
  currentStep: 0,
  hasApp: false,
  isMobile: false,
};

export const tutorialReducer = createReducer<TutorialState>(initialState, (builder) =>
  builder
    .addCase(tutorialActions.reset, () => initialState)
    .addCase(tutorialActions.nextStep, (state, action) => {
      state.currentStep = action.payload;
    })
    .addCase(tutorialActions.setHasApp, (state, action) => {
      state.hasApp = action.payload;
    })
    .addCase(tutorialActions.setIsMobile, (state, action) => {
      state.isMobile = action.payload;
    }),
);
