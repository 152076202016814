import React, { FC } from 'react'
import { Hydrate, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppProps } from 'next/app'
import { useIsServer } from 'vendor/hooks/useIsServer'
import createClientQueryClient from 'src/extensions/client/createClientReactQueryClient'
import { useDispatch } from 'react-redux'

export const ReactQueryClientProvider: FC<AppProps & { children: React.ReactNode }> = ({
  children,
  pageProps,
}) => {
  const ssr = useIsServer()
  const dispatch = useDispatch()
  const [queryClient] = React.useState(createClientQueryClient(dispatch))

  const globalPageProps = pageProps as GlobalStaticProps

  // TODO: Find a way to inject static props to every page!
  if (process.env.NODE_ENV !== 'production') {
    if (!globalPageProps._vendor) {
      throw new Error(
        `Please add
      \`export const getStaticProps = VendorGetStaticProps();\`
      to page ${
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (arguments as any)[4]
      }

      If you are used getStaticPaths with \`fallback: true\` please change to \`fallback: 'blocking'\`
      `,
      )
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={globalPageProps._vendor?.dehydratedState}>{children}</Hydrate>
      {process.env.NODE_ENV !== 'production' && !ssr ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
    </QueryClientProvider>
  )
}
