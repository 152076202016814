import { createWrapper } from 'next-redux-wrapper'
import { Store } from 'redux'
import { rootStateFactory } from 'src/state'

// export an assembled wrapper
export const wrapper = createWrapper<Store>(() => rootStateFactory().store, {
  debug: process.env.NODE_ENV === 'development',
  // https://dev.to/ryyppy/reason-records-nextjs-undefined-and-getstaticprops-5d46
  serializeState: (state) => JSON.parse(JSON.stringify(state)),
})
