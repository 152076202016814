type Param<Str> = Str extends `${infer Prev}/:${infer Name}/${infer Next}`
  ? Param<Prev> | Name | Param<Next>
  : Str extends `${infer Prev}/:${infer Name}`
  ? Param<Prev> | Name
  : Str extends `:${infer Name}/${infer Next}`
  ? Name | Param<Next>
  : Str extends `:${infer Name}`
  ? Name
  : never;

type ParamMap<Str> = Param<Str> extends never ? never : { [T in Param<Str>]: number | string };

export const setParams =
  (...params: string[]) =>
  (route: string) =>
  (...values: string[]) =>
    params.reduce((curr, param, i) => curr.replace(param, values[i]), route);

export const setParamsObject =
  <Str extends string>(route: Str) =>
  (params: ParamMap<Str>): string =>
    route.replace(/:([^/]+)(\/?)/g, (_, param, trail) => {
      const value = params[param as keyof ParamMap<Str>];
      return value !== undefined ? `${String(value)}${trail}` : '';
    });
