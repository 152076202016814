import { useMemo } from 'react'

/**
 * useIsServer shouldn't leverage useEffect as it will cause loosing one render cycle which
 * messes up initial configuration(for client).e.g. binding axios or reconfiguring react querry
 */
export const useIsServer = (): boolean => {
  // isServer is needed to prevent double component rendering on SSR
  return useMemo(() => typeof window === 'undefined', [])
}
