export const layers = {
  layoutContent: 1,
  contentBottom: 10,
  contentTop: 20,
  overlay: 50,
  scrollToTop: 90,
  widget: 100,
  modal: 1000,
  mobileMenu: 2000,
  overMobileMenu: 2100,
  snackbar: 3000,
} as const;
