import { createReducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { NotificationType, snackbarActions } from 'src/state/notification/notificationActions';

export interface NotificationState {
  snackbars: {
    key: string;
    message: SnackbarMessage;
    options?: OptionsObject;
    dismissed?: boolean;
    type?: NotificationType;
  }[];
}

const initialState: NotificationState = {
  snackbars: [],
};

export const notificationReducer = createReducer<NotificationState>(initialState, (builder) =>
  builder
    .addCase(snackbarActions.enqueueSnackbar, (state, action) => {
      state.snackbars.push(castDraft(action.payload));
    })
    .addCase(snackbarActions.closeSnackbar, (state, action) => {
      const notification = state.snackbars.find((a) => a.key === action.payload.key);

      if (notification) {
        notification.dismissed = true;
      }
    })
    .addCase(snackbarActions.closeAllSnackbars, (state) => ({
      snackbars: state.snackbars.map((a) => ({ ...a, dismissed: true })),
    }))
    .addCase(snackbarActions.removeSnackbar, (state, action) => ({
      snackbars: state.snackbars.filter((a) => a.key !== action.payload.key),
    })),
);
