export enum QueryKey {
  Auth = 'auth',
  AvailableCourses = 'available-courses',
  AvailableElearnings = 'available-elearnings',
  AvailableCourseBundles = 'available-course-bundles',
  Banner = 'banner',
  Course = 'course',
  CourseBundle = 'course-bundle',
  CourseBundleElearnings = 'course-bundle-elearnings',
  CourseBundleEvents = 'course-bundle-events',
  CourseBundleList = 'course-bundle-list',
  CourseBundleMaterials = 'course-bundle-materials',
  CourseCategories = 'course-categories',
  CourseEvent = 'course-event',
  CoursesEventList = 'courses-event-list',
  CourseEvents = 'course-events',
  CurrentUserCalendarEvents = 'current-user-calendar-events',
  CurrentUserElearningCertificate = 'current-user-elearning-certificate',
  CurrentUserElearnings = 'current-user-elearnings',
  CurrentUserEventCertificate = 'current-user-event-certificate',
  CurrentUserEventParticipantStatus = 'current-user-event-participant-status',
  CurrentUserEventSurveyStatus = 'current-user-event-survey-status',
  CurrentUserIncomingEvents = 'current-user-incoming-events',
  CurrentUserOngoingElearnings = 'current-user-ongoing-elearnings',
  CurrentUserPastEvents = 'current-user-past-events',
  Elearning = 'elearning',
  ElearningEventList = 'elearning-event-list',
  ElearningLessonVideo = 'elearning-lesson-video',
  ElearningLessons = 'elearning-lessons',
  ElearningMaterials = 'elearning-materials',
  ElearningVideo = 'elearning-video',
  EventRecording = 'event-recording',
  Partner = 'partner',
  RandomSpeakers = 'random-speakers',
  RelatedCourses = 'related-courses',
  Speaker = 'speaker',
  SpeakerList = 'speaker-list',
  SpeakerRelatedCourses = 'speaker-related-courses',
  SpeakersFilters = 'speakers-filters',
  StaticPage = 'static-page',
  Survey = 'survey',
  User = 'user',
  UserCertificates = 'user-certificates',
  UserCompletedCourseBundles = 'user-completed-course-bundles',
  UserCourseBundleEnrollmentStatus = 'user-course-bundle-enrollment-status',
  UserCourseMaterials = 'user-course-materials',
  UserCourseMaterialsTitles = 'user-course-materials-titles',
  UserOngoingCourseBundles = 'user-ongoing-course-bundles',
  UserSummary = 'user-summary',
  WebinarJoinUrl = 'webinar-join-url',
}
