import { DefaultAppWrappers } from '../../components/DefaultApp/DefaultApp'
import React, { useEffect } from 'react'
import { createTheme, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import getConfig from 'next/config'
import { AppProps } from 'next/app'

function JSS({ children }: AppProps & { children: React.ReactNode }) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return <>{children}</>
}

const muiTheme = createTheme(getConfig().publicRuntimeConfig.muiTheme)

function MuiTheme({ children }: AppProps & { children: React.ReactNode }) {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </StylesProvider>
  )
}

DefaultAppWrappers.unshift(JSS, MuiTheme)
