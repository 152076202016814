import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const OnlineIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 5.81817c-14.4598 0-26.18182 11.72204-26.18182 26.18184 0 14.4598 11.72202 26.1818 26.18182 26.1818 14.4598 0 26.1818-11.722 26.1818-26.1818S46.4598 5.81817 32 5.81817zM0 32.00001C0 14.32691 14.3269-.00001 32-.00001c17.6731 0 32 14.32692 32 32.00002 0 17.6731-14.3269 32-32 32-17.6731 0-32-14.3269-32-32z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 32.9092c0-1.6067 1.30244-2.9091 2.90909-2.9091H61.0909c1.6067 0 2.9091 1.3024 2.9091 2.9091 0 1.6066-1.3024 2.9091-2.9091 2.9091H2.90909C1.30244 35.8183 0 34.5158 0 32.9092z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M23.2734 31.52644c.1994 8.945 3.2757 17.5568 8.7266 24.5795 5.4509-7.0227 8.5272-15.6345 8.7266-24.5795-.1994-8.945-3.2757-17.5568-8.7266-24.57952-5.4509 7.02272-8.5272 15.63453-8.7266 24.57952zM32 2.43551L29.8521.47356C22.1 8.96033 17.6946 19.97404 17.4552 31.46584a2.7206 2.7206 0 000 .1212c.2394 11.4918 4.6448 22.5055 12.3969 30.9922a2.90905 2.90905 0 002.1479.9472c.8172 0 1.5968-.3438 2.1479-.9472 7.7521-8.4867 12.1575-19.5004 12.3969-30.9922a2.7206 2.7206 0 000-.1212C46.3054 19.97404 41.9 8.96034 34.1479.47356z"
    />
  </IconBox>
);

export default OnlineIcon;
