import { AuthorizedAxios, BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { LoginDTO, RegisterDTO, ResetPasswordDTO, TokenDTO } from 'src/common/models/auth';
import {
  EmailUpdateDTO,
  mapToUserSummary,
  UpdatePasswordDTO,
  UserDTO,
  UserSummaryDTO,
} from 'src/common/models/user';
import * as Sentry from '@sentry/react';
import { purge } from 'src/lib/purge';
import { logout } from 'src/common/services/auth';
import { QueryClient } from 'react-query';

const prepareChangeAvatarFormData = (file: File) => {
  const formData = new FormData();
  formData.set('avatar', file);
  return formData;
};

const setSentryUser = (user: UserDTO): UserDTO => {
  Sentry.setUser({ id: user.id.toString() });
  return user;
};

export const getLoggedInUser = () =>
  AuthorizedAxios.get<UserDTO>('/api/users/me')
    .then(flatten)
    .then(setSentryUser)
    .catch(throwErrorResponse);

export const register = (data: RegisterDTO) =>
  BaseAxios.post<UserDTO>('/api/registrations/user', purge(data))
    .then(flatten)
    .catch(throwErrorResponse);

export const confirmRegistration = (data: TokenDTO) =>
  BaseAxios.post<void>('/api/registrations/confirm', data).catch(throwErrorResponse);

export const requestResetPassword = (data: LoginDTO) =>
  BaseAxios.post<void>('/api/users/password/reset', data).catch(throwErrorResponse);

export const resetPassword = (data: ResetPasswordDTO) =>
  BaseAxios.post<void>('/api/users/password/reset/token', data).catch(throwErrorResponse);

export const confirmEmailChange = ({ token }: TokenDTO) =>
  BaseAxios.put<void>(`/api/users/email/${token}`).then(flatten).catch(throwErrorResponse);

// odpowiedź nie zawiera pola "parter"
export const updateCurrentUser = (data: Partial<UserDTO>) =>
  AuthorizedAxios.put<UserDTO>('/api/users/me', data).then(flatten).catch(throwErrorResponse);

export const changeEmail = (data: EmailUpdateDTO) =>
  AuthorizedAxios.put<void>('/api/users/email', data).then(flatten).catch(throwErrorResponse);

export const changePassword = (data: UpdatePasswordDTO) =>
  AuthorizedAxios.put<void>('/api/users/password', data).then(flatten).catch(throwErrorResponse);

export const changeAvatar = (file: File) =>
  AuthorizedAxios.put<void>('/api/users/me/avatar', prepareChangeAvatarFormData(file))
    .then(flatten)
    .catch(throwErrorResponse);

export const getUserSummary = () =>
  AuthorizedAxios.get<UserSummaryDTO>('/api/users/me/summary')
    .then(flatten)
    .then(mapToUserSummary)
    .catch(throwErrorResponse);

export const deleteAccount = (queryClient: QueryClient) =>
  AuthorizedAxios.delete<UserSummaryDTO>('/api/users/me').then(() => logout(queryClient));
