import { QueryClient } from 'react-query';
import { Dispatch } from 'redux';
import { isErrorResponse, isFlatErrorResponse } from 'src/common/models/error';
import { snackbarActions } from 'src/state/notification/notificationActions';
import { errorMsgs } from 'src/common/services/errors';
import axios from 'axios';

export default function createClientQueryClient(dispatch: Dispatch<any>) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: (errorCount, error) => {
          // If unauthorized, don't repeat
          if (axios.isAxiosError(error) && error.response?.status === 401) return false;
          return errorCount < 2;
        },
      },
      mutations: {
        onError: (response) => {
          if (isErrorResponse(response)) {
            response.errors.forEach((error) => {
              dispatch(
                snackbarActions.enqueueSnackbar({
                  message: errorMsgs[error.errorCode] || error.message,
                  options: { variant: 'error' },
                }),
              );
            });
          } else if (isFlatErrorResponse(response)) {
            dispatch(
              snackbarActions.enqueueSnackbar({
                message:
                  errorMsgs[response.error] || `${response.error}: ${response.error_description}`,
                options: { variant: 'error' },
              }),
            );
          } else {
            dispatch(
              snackbarActions.enqueueSnackbar({
                message: errorMsgs.unknown,
                options: { variant: 'error' },
              }),
            );
          }
        },
      },
    },
  });
}
