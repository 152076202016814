import { useUserQuery } from 'src/common/hooks/useUserQuery';
import { useClientReady } from 'src/lib/SSR/ClientOnly/ClientOnly';

export const useIsLoggedIn = () => {
  const { data, isLoading } = useUserQuery();
  const client = useClientReady();

  return {
    isLoading: !client || isLoading,
    isLoggedIn: data != null,
  };
};
