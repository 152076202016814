import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CloseIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M53.4628 15.0628c1.2497-1.2497 1.2497-3.2758 0-4.5255-1.2496-1.24968-3.2758-1.24968-4.5254 0L32.0001 27.4746 15.0628 10.5373c-1.2496-1.24968-3.2758-1.24968-4.5254 0-1.24972 1.2497-1.24972 3.2758 0 4.5255L27.4746 32 10.5374 48.9373c-1.24972 1.2497-1.24972 3.2758 0 4.5255 1.2496 1.2497 3.2758 1.2497 4.5254 0l16.9373-16.9373 16.9373 16.9373c1.2496 1.2497 3.2758 1.2497 4.5254 0 1.2497-1.2497 1.2497-3.2758 0-4.5255L36.5256 32l16.9372-16.9372z"
    />
  </IconBox>
);

export default CloseIcon;
