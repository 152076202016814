import React from 'react'
import { AppProps } from 'next/app'
import { MutableDefaultAppExtensionsArray } from 'vendor/utils/MutableDefaultAppExtensionsArray'

/**
 * This list contains components that will wrap the whole application.
 * Every component put in the list will be applied in reverse order, eg.
 *
 * DefaultAppWrappers.push(WrapperA)
 * DefaultAppWrappers.push(WrapperB)
 *
 * will result in rendering:
 *
 * <WrapperB {...pageProps}>
 *   <WrapperA {...pageProps}>
 *     ...DefaultAppAppends...
 *     ...PageErrorHandler...
 *     <DefaultContentsWrappers>
 *        <PageComponent {...pageProps}>
 *     </DefaultContentsWrappers>
 *   </WrapperA {...pageProps}>
 * </WrapperB>
 */
export const DefaultAppWrappers = new MutableDefaultAppExtensionsArray<
  React.FunctionComponent<AppProps & { children: React.ReactNode }>
>()
