import React, { createContext, FC, useMemo, useState } from 'react';
import { usePartnerQuery } from 'src/common/hooks/usePartnerQuery';
import { stringify } from 'qs';
import { useIsLoggedIn } from 'src/common/hooks/useIsLoggedIn';
import { StorageKey } from 'src/config/storageKey';
import { useQueryClient } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { useRouter } from 'next/router';
import { useGatsbyLikeNavigate } from 'src/common/migration/useGatsbyLikeNavigate';
import { useIsServer } from 'vendor/hooks/useIsServer';

export const PartnerRefCodeContext = createContext<string | undefined>(undefined);

export const PartnerRefCodeProvider: FC = ({ children }) => {
  const [code, setCode] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const router = useRouter();
  const navigate = useGatsbyLikeNavigate();
  const { isLoggedIn } = useIsLoggedIn();
  const isServer = useIsServer();

  const { partner: partnerUrlRefCode, ...restSearchParams } = router.query;

  const partnerCode = useMemo(() => {
    if (!isServer && typeof partnerUrlRefCode === 'string' && partnerUrlRefCode.length > 0) {
      return partnerUrlRefCode as string;
    } else if (!isServer && sessionStorage.getItem(StorageKey.PartnerRefCode)) {
      return sessionStorage.getItem(StorageKey.PartnerRefCode) ?? undefined;
    }

    if (!isServer) {
      sessionStorage.removeItem(StorageKey.PartnerRefCode);
    }

    return undefined;
  }, [partnerUrlRefCode]);

  useMemo(() => {
    if (isLoggedIn) {
      queryClient.removeQueries([QueryKey.Partner, code]);
      setCode(undefined);

      if (!isServer) {
        sessionStorage.removeItem(StorageKey.PartnerRefCode);
      }
    }
  }, [isLoggedIn]);

  const { isError: isPartnerError } = usePartnerQuery(partnerCode, {
    enabled: !isLoggedIn,
    onSuccess: () => {
      setCode(partnerCode);
      if (!isServer) {
        partnerCode && sessionStorage.setItem(StorageKey.PartnerRefCode, partnerCode);
      }
    },

    retry: false,
  });

  // if there were any errors block render (should be unlocked after navigate)
  if (isPartnerError) {
    if (!isServer) {
      sessionStorage.removeItem(StorageKey.PartnerRefCode);
    }

    const restQueryString = stringify(restSearchParams, {
      addQueryPrefix: true,
    });
    navigate(`${router.pathname}${restQueryString}`, { replace: true }, { shallow: true });
    return null;
  }

  return <PartnerRefCodeContext.Provider value={code}>{children}</PartnerRefCodeContext.Provider>;
};
