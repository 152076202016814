import { GetServerSidePropsResult } from 'next'

/**
 * This global object allows overwriting props generated by previous steps.
 * It allows you to extend every SSR request with additional functionality like cookie parsing or getting user session.
 *
 * Functions added to this list will run after any other steps.
 * Please check {VendorGetServerSideProps} for complete list of steps performed.
 *
 * Each extension MUST return complete {GetServerSidePropsResult} object.
 *
 * These extensions will run ONLY in SSG pages.
 *
 * This object can only be used in SSG extensions files:
 * vendor/mods/*\/ssg.ts
 * extensions/ssg/*.ts
 * src/extensions/ssg/*.ts
 */
export const GlobalServerSidePropsDecorators: Array<
  <T>(
    props: GetServerSidePropsResult<T>,
    ctx: VendorServerSideContext,
  ) => GetServerSidePropsResult<T> | Promise<GetServerSidePropsResult<T>>
> = []

export async function decorateWithServerSidePropsDecorators(
  returnProps: any,
  vendorContext: VendorServerSideContext,
) {
  for (const decorator of GlobalServerSidePropsDecorators) {
    returnProps = await decorator(returnProps, vendorContext)
  }
  return returnProps
}
