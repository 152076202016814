import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { NotificationState } from 'src/state/notification/notificationReducer';

export const notificationStateSelector = (state: RootState) => state.notification;

export const notificationSnackbarsSelector = createSelector(
  notificationStateSelector,
  (state: NotificationState) => state.snackbars,
);
