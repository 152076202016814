import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CheckedIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path d="M32 0C14.3559 0 0 14.3559 0 32s14.3559 32 32 32 32-14.3559 32-32S49.6441 0 32 0zm17.8847 23.5789L29.4336 43.8697c-1.203 1.203-3.1278 1.2832-4.411.0802l-10.8271-9.8647c-1.2832-1.203-1.3634-3.208-.2406-4.4912 1.203-1.2832 3.208-1.3634 4.4912-.1604l8.5815 7.8596 18.2857-18.2857c1.2832-1.2832 3.2882-1.2832 4.5714 0s1.2832 3.2882 0 4.5714z" />
  </IconBox>
);

export default CheckedIcon;
