import { BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { Auth, AuthDTO, AuthReqDTO, mapToAuth } from 'src/common/models/auth';
import { flatten } from 'src/lib/axios';
import { stringify } from 'qs';
import { QueryClient } from 'react-query';
import { deleteFromStorage } from 'src/common/services/queryStorage';
import { QueryKey } from 'src/config/queryKey';
import * as Sentry from '@sentry/react';

export const login = (data: AuthReqDTO) =>
  BaseAxios.post<AuthDTO>(
    'oauth/token',
    stringify({
      ...data,
      grant_type: 'password',
    }),
  )
    .then(flatten)
    .then(mapToAuth)
    .catch(throwErrorResponse);

export const refreshToken = (refreshToken: string) => {
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', refreshToken);

  return BaseAxios.post<AuthDTO>('oauth/token', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
    .then(flatten)
    .then(mapToAuth)
    .catch(throwErrorResponse);
};

export const logout = async (queryClient: QueryClient) => {
  if (queryClient.getQueryData<Auth>(QueryKey.Auth) == null) {
    return;
  }

  Sentry.setUser(null);
  deleteFromStorage('auth');
  // Auth musi zostać zresetowane jako pierwsze: https://jira.rndlab.online/browse/PFP-1536
  await queryClient.resetQueries(QueryKey.Auth);
  await queryClient.resetQueries();

  // left as promise, if we have to make some async calls in the future (invalidate token)
  return Promise.resolve();
};
