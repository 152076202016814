import { colors } from 'src/config/colors';
import styled, { keyframes } from 'styled-components';
import { CSSProperties, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

type BaseAnimationProps = Pick<
  CSSProperties,
  | 'animationDuration'
  | 'animationTimingFunction'
  | 'animationDelay'
  | 'animationIterationCount'
  | 'animationDirection'
  | 'animationFillMode'
  | 'animationPlayState'
  | 'display'
>;

const BaseAnimation = styled.div<BaseAnimationProps>`
  animation-duration: ${(props) => props.animationDuration || '20s'};
  animation-timing-function: ${(props) =>
    props.animationTimingFunction || 'cubic-bezier(0.070, 0.985, 0.060, 1.000);'};
  animation-delay: ${(props) => props.animationDelay || '400ms'};
  animation-iteration-count: ${(props) => props.animationIterationCount || '1'};
  animation-direction: ${(props) => props.animationDirection || 'normal'};
  animation-fill-mode: ${(props) => props.animationFillMode || 'forwards'};
  animation-play-state: ${(props) => props.animationPlayState || 'running'};
  display: ${(props) => props.display || 'block'};
`;

const ProgressAnimation = keyframes`  
  from { width: 0% }
  to { width: 90% }
`;

export const TopBar = styled(BaseAnimation)`
  animation-name: ${ProgressAnimation};
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  background: ${colors.red};
`;

export const TopBarBackground = styled(BaseAnimation)`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: ${colors.white};
`;

export const TopBarLoader = () => {
  const [loading, setLoading] = useState<false | number>(false);
  const router = useRouter();

  useEffect(() => {
    const startLoading = () => setLoading(Math.random());
    const stopLoading = () => setLoading(false);

    router.events.on('routeChangeStart', startLoading);
    router.events.on('routeChangeComplete', stopLoading);
    router.events.on('routeChangeError', stopLoading);

    return () => {
      router.events.off('routeChangeStart', startLoading);
      router.events.off('routeChangeComplete', stopLoading);
      router.events.off('routeChangeError', stopLoading);
    };
  }, [router]);

  return loading ? (
    <TopBarBackground>
      <TopBar key={loading} />
    </TopBarBackground>
  ) : null;
};
