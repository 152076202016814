import { createAction } from 'src/lib/createAction';
import { createAction as toolkitCreateAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarMessage } from 'notistack';

export type NotificationType = 'light' | 'standard';

export const snackbarActions = {
  enqueueSnackbar: toolkitCreateAction(
    'notification/snackbar/enqueue',
    ({
      key,
      message,
      options,
      type,
    }: {
      key?: string;
      message: SnackbarMessage;
      options?: OptionsObject;
      type?: NotificationType;
    }) => ({
      payload: {
        key: key ?? `${new Date().getTime() + Math.random()}`,
        message,
        options,
        type,
      },
    }),
  ),
  closeSnackbar: createAction('notification/snackbar/close')<{
    key: string;
  }>(),
  closeAllSnackbars: createAction('notification/snackbar/closeAll')(),
  removeSnackbar: createAction('notification/snackbar/remove')<{
    key: string;
  }>(),
};
