import { fromNullable, Option, toNullable } from 'fp-ts/lib/Option';
import { createTransform } from 'redux-persist';

function isOption(x: any): x is Option<any> {
  return Object.hasOwn(x, '_tag') && (x._tag === 'None' || x._tag === 'Some');
}

export const createOptionalTransform = (keys: string[]) =>
  createTransform<any, any, any>(
    (inboundState, key) => {
      if (keys.indexOf(String(key)) !== -1 && isOption(inboundState)) {
        return toNullable(inboundState);
      }

      return inboundState;
    },
    (outboundState, key) => {
      if (keys.indexOf(String(key)) !== -1) {
        return fromNullable(outboundState);
      }

      return outboundState;
    },
  );
