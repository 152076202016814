import styled from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import React, { ButtonHTMLAttributes, ComponentType, FC } from 'react';
import { TextDecoration } from 'src/common/styles/typography';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import {
  ButtonKind,
  buttonKindConfig,
  buttonKindLoadingConfig,
  buttonLoadingSizeConfig,
  ButtonSize,
  buttonSizeConfig,
  buttonSpacingConfig,
  buttonSpacingLoadingConfig,
} from 'src/common/components/Button/config';
import { StyleProps } from 'src/lib/styleProps';

const StyledLoadingIcon = styled(LoadingIcon)`
  color: ${colors.red};
  margin: -0.625rem 0 -0.625rem 0.625rem;
  position: relative;
  top: -0.0625rem;
`;

export interface CleanButtonAttrs extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: JSX.IntrinsicElements | ComponentType<unknown>;
}

export const CleanButton = styled.button.attrs<CleanButtonAttrs>(
  ({ as, type }) =>
    !as && {
      type: type ?? 'button',
    },
)`
  background: transparent;
  border: 0;
  border-radius: 0;
  color: currentColor;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export interface BaseButtonProps extends StyleProps {
  block?: boolean;
  decoration?: TextDecoration;
  href?: string;
  kind?: ButtonKind;
  size?: ButtonSize;
}

export type ButtonProps = BaseButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = styled(CleanButton)<ButtonProps>`
  display: inline-block;
  transition: background 0.2s ease;

  &:focus:not(:disabled) {
    outline: 0.0625rem dashed ${colors.red};
    outline-offset: 0.1875rem;
    z-index: 20;
  }

  &:hover:not(:disabled) {
    box-shadow: ${shadows.redHover};
  }

  ${({ kind }) => buttonKindConfig[kind ?? 'primary']};
  ${({ kind, size }) => kind !== 'link' && buttonSpacingConfig[size ?? 'default']};
  ${({ size }) => buttonSizeConfig[size ?? 'default']};
`;

export interface LoaderButtonProps extends ButtonProps {
  loading?: boolean;
}

const LoaderButton = styled(Button)<LoaderButtonProps>`
  ${({ kind, loading }) => loading && buttonKindLoadingConfig[kind ?? 'primary']};
  ${({ kind, loading, size }) =>
    !loading && kind !== 'link' && buttonSpacingLoadingConfig[size ?? 'default']};
`;

export const ButtonWithLoader: FC<LoaderButtonProps> = ({ children, loading, ...props }) => (
  <LoaderButton disabled={loading} {...props}>
    {children}

    {loading && <StyledLoadingIcon size={buttonLoadingSizeConfig[props.size ?? 'default']} />}
  </LoaderButton>
);
