import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const ChatIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M34.1253 60.7495c16.7755 0 30.3747-13.5993 30.3747-30.3748S50.9008 0 34.1253 0 3.75051 13.5992 3.75051 30.3747c0 4.3991.93516 8.5798 2.61752 12.354L.664688 59.8004c-.383275 1.1472-.085663 2.4126.768812 3.2687.85448.8561 2.11923 1.1561 3.26721.7751L21.83 58.1581c3.7589 1.6659 7.919 2.5914 12.2953 2.5914zM10.1765 30.3743c0 2.5686.4044 5.0428 1.1529 7.3623l.0143.0045c.3766 1.1658.8401 2.2924 1.383 3.3724.24.6741.311 1.3875.2218 2.0797L8.74692 55.7697l12.67848-4.2086c.6167-.078 1.2384-.0258 1.8256.1457 1.2673.6477 2.6 1.1857 3.9856 1.6015l.0025.0108c2.1812.6538 4.4932 1.0051 6.8873 1.0051 13.2271 0 23.9499-10.7227 23.9499-23.9499 0-13.2271-10.7228-23.94987-23.9499-23.94987-13.2272 0-23.9499 10.72277-23.9499 23.94987z"
    />
  </IconBox>
);

export default ChatIcon;
