import Axios, { AxiosError } from 'axios';

export interface ApiError<T = any, E = any> {
  errors: Error<T, E>[];
}

export interface FlatError {
  error: string;
  error_description: string;
}

export interface Error<T = any, E = any> {
  data?: T;
  errorCode: string & E;
  message: string;
}

export interface ErrorResponse<T = any> {
  errors: Error<T>[];
  status: number;
}

export interface FlatErrorResponse {
  error: string;
  error_description: string;
  status: number;
}

export interface HttpErrorResponse {
  error: string;
  status: number;
}

export function isErrorResponse(response: any): response is ErrorResponse {
  return response?.status && Array.isArray(response?.errors);
}

export function isFlatErrorResponse(response: any): response is FlatErrorResponse {
  return response.error && response.error_description;
}

export function isAxiosError(a: unknown): a is AxiosError {
  return Axios.isAxiosError(a);
}
