import { createAction } from 'src/lib/createAction';
import { CourseViewType } from 'src/state/courseListing/courseListingReducer';

export const courseListingActions = {
  clearFilters: createAction('courseListing/clearFilters')(),
  selectCategory: createAction('courseListing/selectCategory')<number>(),
  setShowAllCategories: createAction('courseListing/showAllCategories')<boolean>(),
  setTotalOnlineCourses: createAction('courseListing/setTotalOnlineCourses')<number>(),
  setTotalElearningCourses: createAction('courseListing/setTotalElearningCourses')<number>(),
  setTotalCourseBundles: createAction('courseListing/setTotalCourses')<number>(),
  toggleCategory: createAction('courseListing/toggleCategory')<number>(),
  toggleLevel: createAction('courseListing/toggleLevel')<string>(),
  toggleSpeaker: createAction('courseListing/toggleSpeaker')<number>(),
  toggleCourseTypeView: createAction('courseListing/toggleCourseTypeView')<CourseViewType>(),
  setPageIndex: createAction('courseListing/setPageIndex')<number>(),
};
