import React from 'react';
import { Seo } from 'src/public/components/Seo';
import { VendorGetStaticProps } from 'vendor/utils/VendorGetStaticProps';

const NotFoundPage = () => (
  <div>
    <Seo title="404" />
    <h1>Strona, której szukasz, nie została znaleziona</h1>
  </div>
);

export const getStaticProps = VendorGetStaticProps(() => ({
  props: {},
  revalidate: false,
}));

export default NotFoundPage;
