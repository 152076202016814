import { useRouter } from 'next/router';
import { UrlObject } from 'url';

export const useGatsbyLikeNavigate = () => {
  const router = useRouter();
  return (
    url: UrlObject | string,
    options?: {
      replace?: boolean;
      state?: any;
    },
    nextOptions?: {
      shallow?: boolean;
      locale?: string | false;
      scroll?: boolean;
    },
  ): Promise<void> => {
    if (options?.replace) {
      return router.replace(url, undefined, nextOptions).then(() => undefined);
    } else {
      return router.push(url, undefined, nextOptions).then(() => undefined);
    }
  };
};
