import { AxiosRequestConfig } from 'axios';
import { Auth } from 'src/common/models/auth';

export const setAuthHeader = (token: string, config: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  },
});

export interface AccessTokenInterceptorProps {
  getAuthData: () => Auth | undefined;
}

export const createAccessTokenInterceptor =
  ({ getAuthData }: AccessTokenInterceptorProps) =>
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const auth = getAuthData();

    if (auth == null) {
      return config;
    } else {
      return setAuthHeader(auth.access_token, config);
    }
  };
