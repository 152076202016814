import { useUserQuery } from 'src/common/hooks/useUserQuery';
import { QueryKey } from 'react-query/types/core/types';
import { useCallback, useMemo } from 'react';

export interface EnhancedKey {
  key: QueryKey;
  isPublic: boolean;
  isAuthenticated: boolean;
}

const unauthenticatedKey = 'UNAUTHENITCATED';

export const useCurrentUserKey = () => {
  const { data: user } = useUserQuery();
  return user?.id.toString() ?? unauthenticatedKey;
};

const buildEnhancedKey = (key: QueryKey, userKey: string) => ({
  key: Array.isArray(key) ? [...key, userKey] : [key, userKey],
  isPublic: userKey === unauthenticatedKey,
  isAuthenticated: userKey !== unauthenticatedKey,
});

export const buildUnauthenticatedUserKeyEnhancer = (key: QueryKey): EnhancedKey =>
  buildEnhancedKey(key, unauthenticatedKey);

export const useCurrentUserKeyEnhancer = (key: QueryKey): EnhancedKey => {
  const userKey = useCurrentUserKey();
  return useMemo(() => buildEnhancedKey(key, userKey), [key, userKey]);
};

export const useCurrentUserKeyEnhanceFactory = () => {
  const userKey = useCurrentUserKey();
  return useCallback((key: QueryKey): EnhancedKey => buildEnhancedKey(key, userKey), [userKey]);
};
