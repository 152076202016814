import { VariantType } from 'notistack';
import { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { StoreSnackbarIcon } from 'src/common/components/StoreSnackbarController/StoreSnackbarIcon';
import { FC } from 'react';
import { IconProps } from 'src/lib/IconBox';
import InfoIcon from 'src/assets/icons/InfoIcon';
import WarningIcon from 'src/assets/icons/WarningIcon';
import CheckedIcon from 'src/assets/icons/CheckedIcon';

export type CustomVariantType = 'errorLight' | 'infoLight' | 'successLight';

export const lightTypeVariantMapper = {
  default: 'default',
  error: 'errorLight',
  info: 'infoLight',
  success: 'successLight',
  warning: 'warning',
};

export interface SnackbarMessageThemeParams {
  variant: VariantType | CustomVariantType;
}

export interface SnackbarMessageStyledProps {
  theme: SnackbarMessageThemeParams;
}

export const messageVariants: Record<VariantType | CustomVariantType, ReturnType<typeof css>> = {
  default: css`
    background: ${colors.blue};
    color: ${colors.white};

    &::before {
      background: ${colors.white};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.white};
    }
  `,
  error: css`
    background-color: ${colors.beautyBush};

    &::before {
      background: ${colors.red};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.red};
    }
  `,
  errorLight: css`
    background-color: ${colors.white};

    &::before {
      background: ${colors.red};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.red};
    }
  `,
  info: css`
    background: ${colors.blue};
    color: ${colors.white};

    &::before {
      background: ${colors.white};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.white};
    }
  `,
  infoLight: css`
    background: ${colors.white};
    color: ${colors.blue05};

    &::before {
      background: ${colors.blue05};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.blue05};
    }
  `,
  success: css`
    background-color: ${colors.feta};

    &::before {
      background: ${colors.amazon};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.amazon};
    }
  `,
  successLight: css`
    background-color: ${colors.white};

    &::before {
      background: ${colors.green1};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.green1};
    }
  `,
  warning: css`
    background-color: ${colors.colonialWhite};

    &::before {
      background: ${colors.pfp3bis};
    }

    ${StoreSnackbarIcon} {
      color: ${colors.pfp3bis};
    }
  `,
};
export const variantIcons: Record<VariantType | CustomVariantType, FC<IconProps>> = {
  default: InfoIcon,
  error: WarningIcon,
  errorLight: WarningIcon,
  info: InfoIcon,
  infoLight: InfoIcon,
  success: CheckedIcon,
  successLight: CheckedIcon,
  warning: WarningIcon,
};
