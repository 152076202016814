import { VendorApp } from 'vendor/utils/VendorApp';
import {
  DefaultApp,
  DefaultAppAppends,
  DefaultAppWrappers,
} from 'vendor/components/DefaultApp/DefaultApp';
import 'vendor/styles/global.scss';
import { SnackbarProvider } from 'src/common/providers/SnackbarProvider';
import { TrackingScripts } from 'src/common/components/TrackingScripts';
import 'swiper/css/bundle';
import 'src/assets/styles.scss';
import 'src/config/fonts';
import { GlobalStaticPropsDecorators } from 'vendor/utils/GlobalStaticPropsDecorators';
import { GlobalServerSidePropsDecorators } from 'vendor/utils/GlobalServerSidePropsDecorators';
import { merge } from 'lodash-es';
import { getCourseCategoriesDTO } from 'src/common/services/course';
import { GlobalPagePropsContext } from 'src/common/services/globalPagePropsContext';
import { TopBarLoader } from 'src/common/components/TopPageLoader/TopPageLoader';
import { PartnerRefCodeProvider } from 'src/common/providers/PartnerRefCodeProvider';
import getConfig from 'next/config';
import { version } from 'package.json';

DefaultAppWrappers.push(PartnerRefCodeProvider);
DefaultAppWrappers.push(SnackbarProvider);

DefaultAppAppends.push(TrackingScripts);
DefaultAppAppends.push(TopBarLoader);

// Provide Gatsby-like properties to pages
GlobalStaticPropsDecorators.push((props, ctx) => {
  return merge(
    {
      props: ctx.params,
    },
    props,
  );
});

// Global page props
DefaultAppWrappers.push(({ pageProps, children }) => (
  <GlobalPagePropsContext.Provider value={pageProps}>{children}</GlobalPagePropsContext.Provider>
));

GlobalStaticPropsDecorators.push(async (props) => {
  return merge(
    {
      props: {
        courseCategories: await getCourseCategoriesDTO(),
      },
      revalidate: 60,
    },
    props,
  );
});

GlobalServerSidePropsDecorators.push(async (props, ctx) => {
  const location = { state: ctx.query };
  return merge(
    {
      props: {
        location: location,
      },
    },
    props,
  );
});

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.version = {
    version,
    environmentName: getConfig().publicRuntimeConfig.app.env,
  };
}

export default VendorApp(DefaultApp);
