import { createReducer } from '@reduxjs/toolkit';
import { none, Option, some } from 'fp-ts/lib/Option';
import { cookiesActions } from 'src/state/cookies/cookiesActions';

export interface CookiesState {
  isAccepted: Option<boolean>;
}

const initialState: CookiesState = {
  isAccepted: none,
};

export const cookiesReducer = createReducer<CookiesState>(initialState, (builder) =>
  builder
    .addCase(cookiesActions.accept, (state) => {
      state.isAccepted = some(true);
    })
    .addCase(cookiesActions.decline, (state) => {
      state.isAccepted = some(false);
    }),
);
